import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { RestService, StorageService } from '@impesa/ngx-core';
import { CreateKycPerson } from '@impesa/ngx-kyc/lib/models/kyc/person/request/create-kyc-person.dto';
import { KycPersonDto } from '@impesa/ngx-kyc/lib/models/kyc/person/response/kyc-person.model';
import { Billing, CreateBillingDto } from '@models/billings';
import { ClientType } from '@models/client-type';
import { contact } from '@models/contact.model';
import {
    UpdateDestinationAccountStatusDto,
    attachmentResponse,
    destinationAccount,
    destinationAccountResponse,
    destinationAccountTypesResponse,
} from '@models/destination-account/dtos/destination-account';
import { identificationType } from '@models/identification-type';
import { issuerAccount } from '@models/issuer-account';
import { kycAttachment } from '@models/kyc-attachment.dto';
import { personKyc } from '@models/kyc.model';
import { Notices } from '@models/notices.model';
import { Notification } from '@models/notifications';
import { PaypalAccount } from '@models/paypal-accounts';
import { PrepaidCard } from '@models/prepaid-card.model';
import { CountrySettings } from '@models/settings/country-settings.interface';
import { transactions } from '@models/transactions.model';
import { CreateUserDto } from '@models/users/dtos/create-user.dto';
import { UpdateUserDto } from '@models/users/dtos/update-user.dto';
import { User } from '@models/users/user.model';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';
import { Observable, Subject, of } from 'rxjs';
import * as CRI from '../../assets/CRI/template.json';
import * as PAN from '../../assets/PAN/template.json';
import * as GTM from '../../assets/GTM/template.json';
import { NotificationStatusEnum } from '../enums/notification-status.enum';
import { file } from '../models/destination-account/file.model';
import { PrepaidBalance } from '@models/prepaid-card/prepaid-balance.model';

@Injectable()
export class KipoService {
    private api: string = 'kipo';
    private auth: string = 'auth';
    private complianceApiName = 'compliance';
    private moniByte: string = 'moniByte';
    private countryTemplateMap: any = {
        CRI,
        PAN,
        GTM,
    };
    private getIdentificationTypeList!: Observable<identificationType[]>;
    private userUpdated$ = new Subject<any>();

    constructor(
        private readonly http: HttpClient,
        private readonly restService: RestService,
        private readonly globalVarsService: GlobalVarsService,
        private readonly storageService: StorageService,
    ) {}

    public getCountryContent() {
        const countryCode = this.getCountry();
        return of(this.countryTemplateMap[countryCode]);
    }

    public getCountry() {
        return this.storageService.get('country');
    }

    public getUserData(token?: string): Observable<User> {
        if (token) {
            return this.restService.get(this.api, 'users', {
                Authorization: `Bearer ${token}`,
            });
        }
        return this.restService.get(this.api, 'users');
    }

    public getPrepaidRequestStatus(): Observable<any> {
        return this.restService.get(this.api, `prepaid-accounts/requestStatus`);
    }

    public getPrepaidRequestInfo(): Observable<any> {
        return this.restService.get(this.api, `prepaid-accounts`);
    }

    public updateUser(userInfo: User) {
        this.userUpdated$.next(userInfo);
    }

    public userUpdated(): Observable<any> {
        return this.userUpdated$.asObservable();
    }

    public getNotifications(
        status: string = NotificationStatusEnum.NEW,
    ): Observable<Notification[]> {
        return this.restService.get(this.api, `notifications?status=${status}`);
    }

    public readNotification(messageId: string): Observable<number> {
        return this.restService.patch(
            this.api,
            `notifications/${messageId}/read`,
        );
    }

    public readUserNotifications(): Observable<number> {
        return this.restService.patch(this.api, `notifications/read`);
    }

    public getClientTypes(): Observable<ClientType[]> {
        return this.restService.get(this.api, 'users/clientTypes');
    }

    public getIdentificationTypes(): Observable<identificationType[]> {
        if (this.getIdentificationTypeList) {
            return this.getIdentificationTypeList;
        }
        this.getIdentificationTypeList = this.restService.get(
            this.api,
            `identification-types`,
        );
        return this.getIdentificationTypeList;
    }

    public getIdentificationTypeById(
        id: string,
    ): Observable<identificationType> {
        return this.restService.get(this.api, `identification-types/${id}`);
    }

    public addBillingAccount(billing: CreateBillingDto): Observable<Billing> {
        return this.restService.post(this.api, 'billings', billing);
    }

    public postPaypalAccount(code: string): Observable<PaypalAccount> {
        return this.restService.post(this.api, 'paypal-accounts', {
            code: code,
        });
    }

    public getUserPayPalAccounts(): Observable<PaypalAccount[]> {
        return this.restService.get(this.api, 'paypal-accounts');
    }
    public getUserOriginAccounts(
        productType: string,
    ): Observable<PaypalAccount[]> {
        return this.restService.get(
            this.api,
            `paypal-accounts/${productType}/productType`,
        );
    }

    public checkIdNumberAvailable(idNumber: string): Observable<boolean> {
        return this.restService.get(this.api, `users?idNumber=${idNumber}`);
    }

    public putUserData(userData: UpdateUserDto): Observable<any> {
        return this.restService.patch(this.api, `users`, userData);
    }

    public postUserData(userData: CreateUserDto): Observable<User> {
        return this.restService.post(this.api, `users`, userData);
    }

    public requestPrepaidCard(prepaidCard: PrepaidCard): Observable<any> {
        return this.restService.post(this.api, `prepaid-accounts`, prepaidCard);
    }

    public personKycData(
        userData: CreateKycPerson,
        method: string,
    ): Observable<KycPersonDto> {
        if (method == 'Post') {
            return this.restService.post(this.api, `users/person`, userData);
        } else {
            return this.restService.patch(this.api, `users/person`, userData);
        }
    }

    public addDestinationAccount(
        jsonData: destinationAccount,
    ): Observable<destinationAccountResponse> {
        return this.restService.post(
            this.api,
            `destination-accounts`,
            jsonData,
        );
    }

    public updateDestinationAccount(
        jsonData: destinationAccount,
    ): Observable<destinationAccountResponse> {
        return this.restService.patch(
            this.api,
            `destination-accounts`,
            jsonData,
        );
    }

    updateDestinationAccountStatus(
        id: string,
        destinationAccountStatus: UpdateDestinationAccountStatusDto,
    ): Observable<destinationAccount> {
        return this.restService.patch(
            this.api,
            `destination-accounts/inactive/${id}`,
            destinationAccountStatus,
        );
    }

    public addDestinationAccountAttachement(
        destinationAccountId: string,
        name: string,
        filedata: file,
    ): Observable<attachmentResponse> {
        const body = new FormData();
        body.append('file', filedata.buffer);

        let params: HttpParams = new HttpParams();
        params = params.set('type', name);

        const api = environment.apis.kipo.url;
        const url = `${api}/attachments/destination-accounts/${destinationAccountId}?${params}`;

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');

        return this.http.post<attachmentResponse>(url, body, { headers });
    }

    getAttachment(filePath: string): Observable<any> {
        const api = environment.apis.kipo.url;
        let _url: string = `${api}/${filePath}`;

        const customHeader = {
            responseType: 'arraybuffer' as 'json',
        };

        return this.http.get<any>(_url, customHeader);
    }

    public getTransactionsHistory(): Observable<transactions> {
        return this.restService.get(this.api, `withdrawals/transactions`);
    }

    public getTransactionsbyRange(
        toDate: string,
        fromDate: string,
    ): Observable<[any]> {
        return this.restService.get(
            this.api,
            `withdrawals/transactions?startDate=${toDate}&endDate=${fromDate}`,
        );
    }

    public getDestinationAccountsByUser(
        id: string,
    ): Observable<destinationAccountResponse[]> {
        return this.restService.get(this.api, `destination-accounts/${id}`);
    }

    public getBalance(sessionToken: string): Observable<any> {
        let header = {
            sessiontoken: sessionToken,
        };
        return this.restService.get(
            this.api,
            `paypal-accounts/balance`,
            header,
        );
    }

    public getCardBalance(productId: string): Observable<PrepaidBalance> {
        return this.restService.get(
            this.api,
            `prepaid-accounts/prepaidBalance/${productId}`,
        );
    }

    public getKipoLimits(): Observable<[any]> {
        return this.restService.get(this.api, `withdrawals/limits`);
    }

    public getKipoFees(): Observable<any> {
        return this.restService.get(this.api, `withdrawals/fees`);
    }

    public postPaypalWithdraw(jsonData: any): Observable<any> {
        let paypalAccount = this.globalVarsService.getPaypalAccount();

        if (Array.isArray(paypalAccount)) {
            paypalAccount = paypalAccount[0];
        }

        let header = {
            sessiontoken:
                paypalAccount?.sessionToken || jsonData.sinpeAccountId,
        };
        jsonData = {
            ...jsonData,
            destinationAccountId: jsonData.sinpeAccountId,
        };
        delete jsonData['sinpeAccountId'];
        return this.restService.post(this.api, `withdrawals`, jsonData, header);
    }

    public deleteAccount(jsonData: any): Observable<string> {
        return this.restService.del(
            this.api,
            `destination-accounts/${jsonData.accountId}`,
        );
    }

    public getIssuers(): Observable<[issuerAccount]> {
        return this.restService.get(this.api, 'issuer-accounts/list');
    }

    public getAccountType(): Observable<destinationAccountTypesResponse[]> {
        return this.restService.get(this.api, 'destination-account-types');
    }

    public checkKycUser(): Observable<boolean> {
        return this.restService.get(this.api, `users/validateKyc`, Headers);
    }

    public savePersonKyc(kyc: personKyc): Observable<any> {
        return this.restService.post(this.api, `kyc-persons`, kyc);
    }

    public updatePersonKyc(kyc: personKyc): Observable<any> {
        return this.restService.patch(this.api, `kyc-persons`, kyc);
    }

    public addKycPersonAttachement(
        name: string,
        filedata: file,
    ): Observable<kycAttachment> {
        const body = new FormData();
        body.append('file', filedata.buffer);

        let params: HttpParams = new HttpParams();
        params = params.set('type', name);

        const api = environment.apis.kipo.url;
        const url = `${api}/attachments/kyc-persons?${params}`;

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');

        return this.http.post<kycAttachment>(url, body, { headers });
    }

    public verifyAccount(token: string): Observable<any> {
        return this.restService.post(this.auth, `users/verify`, {
            headers: new HttpHeaders().set('Authorization', `Bearer ${token}`),
        });
    }

    public sendMembership(form: contact): Observable<contact> {
        return this.restService.post('kipo', `contacts`, form);
    }

    public deleteAttachment(attachmentId: any): Observable<string> {
        return this.restService.del(this.api, `attachments/${attachmentId}`);
    }

    public getCountrySettingsByCode(code: string): Observable<CountrySettings> {
        return this.restService.get(this.api, `users/settings/${code}`);
    }

    public getNotices(): Observable<Notices[]> {
        return this.restService.get(this.api, `news`);
    }

    public getDistricts(stateId: string, cityId: string) {
        return this.restService.get(
            this.complianceApiName,
            `countries/states/${stateId}/cities/${cityId}/districts`,
        );
    }
}
