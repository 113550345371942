<div class="box">
    <div class="notifications">
        <a class="row">
            <div class="col-sm-4 offset-4 pt-2">
                <img
                    width="70"
                    src="./assets/kipo/logo-kipo-gray.svg"
                    alt="Kipo icon"
                />
            </div>
            <div class="col-sm-4 pt-3">
                <i
                    class="material-icons float-right icon-close-all navNotificator-clearAll-notifications"
                    (click)="readUserNotifications()"
                    title="Remover todo"
                >
                    clear_all
                </i>
            </div>
        </a>
        <ul class="notify">
            <li
                class="icon justify-content-between"
                *ngFor="let item of _notifications"
            >
                <span
                    class="text"
                    (click)="validateMessageType(item.description)"
                    >{{ item.description }}</span
                >
                <i
                    class="material-icons icon-close-item navNotificator-closeItem"
                    title="Close"
                    (click)="readNotification(item.id)"
                >
                    highlight_off
                </i>
            </li>
        </ul>
    </div>
</div>
