export enum UserStatusesEnum {
    ACT = 'ACT',
    DEA = 'DEA',
    BLK = 'BLK',
    APR = 'APROV',
    REJ = 'REJTD',
    REV = 'REVID',
    DEN = 'DENID',
    REG = 'REGIS',
    COM = 'CMPLT',
    RVS = 'REVIDS',
    CLS = 'CLOSED',
}
