import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
    MatSelectionList,
    MatSelectionListChange,
} from '@angular/material/list';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { DestinationAccountComponent } from '@components/destinationAccount/destination-account.component';
import { KipoService } from '@core/kipo.service';
import { LimitStatusError } from '@enums/limits-status-error.enum';
import { PRODUCT_TYPES } from '@enums/product-types.enum';
import { UserEnableTransact } from '@enums/user-enable-transact.enum';
import { UserStatusesEnum } from '@enums/user-statuses.enum';
import { BaseComponent, BootService } from '@impesa/ngx-core';
import { destinationAccount } from '@models/destination-account/dtos/destination-account';
import { PaypalAccount } from '@models/paypal-accounts';
import { CountrySettings } from '@models/settings/country-settings.interface';
import { withdrawLimits } from '@models/withdraw.model';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { KipoError } from '../../../enums/kipo-errors.enum';
import { CalculatorFormComponent } from '../../calculator/calculator-form/calculator-form.component';
import { DialogComponent } from '../dialog/dialog.component';
import { InactiveDisclaimerComponent } from '../../destinationAccount/inactive-disclaimer/inactive-disclaimer.component';

@Component({
    selector: 'app-withdraw',
    templateUrl: './withdraw.component.html',
    styleUrls: ['./withdraw.component.scss'],
})
export class WithdrawComponent
    extends BaseComponent
    implements OnInit, AfterViewInit
{
    @ViewChild('stepper', { static: false }) _stepper!: MatStepper;
    @ViewChild('sinpeCards', { static: false }) _sinpeCards!: MatSelectionList;
    @ViewChild(CalculatorFormComponent)
    calculator!: CalculatorFormComponent;
    @Input() accumulateFeeValue: any;
    public _viewInMobileMode = true;
    public _viewInTabletMode = false;
    public _showAddIbanForm = false;
    public _formLoadError: any;
    public _PayPalAccount!: PaypalAccount;
    public _showRequest!: any;
    public _PrepaidAccount!: PaypalAccount;

    public _PayPalBalance!: any;
    public _SinpeAccounts: any[] = [];
    public _SelectSinpe!: any;
    public issuers: any[] = [];

    public _KipoLimits!: any;
    public _KipoFees!: any;
    public kipoLimits!: withdrawLimits[];

    public _taxes: number = 0;
    public _userProfile!: any;
    public _destinationAccount!: destinationAccount;

    public _withdrawForm!: UntypedFormGroup;

    public _transaction: any;
    public _issuerName = '';

    public _netAmount = 0;
    public _netFees = 0;
    public _IVAFee = 0;

    public calculatorData!: any;

    public showDisclaimerAditionalInfo: boolean = false;
    public isLoading: boolean = false;

    public showAlertRejected: boolean = false;
    public showAlertReview: boolean = false;
    public showAlertInactive: boolean = false;
    public showAlertCompleteKyc: boolean = false;
    public showAlertPendingAccount: boolean = true;
    public showWithdraw: boolean = false;
    public showRequest: boolean = false;
    public _card: boolean = false;
    public showViewInfoLabel: boolean = false;
    public countrySettings!: CountrySettings;
    public template: any = null;
    public label: any;
    private productType: string = PRODUCT_TYPES.PAYPAL;

    constructor(
        boot: BootService,
        private kipoService: KipoService,
        private globalService: GlobalVarsService,
        private actRouter: ActivatedRoute,
        public dialog: MatDialog,
        private cdr: ChangeDetectorRef,
    ) {
        super(boot);

        this.actRouter.data.subscribe((response: any) => {
            this.countrySettings =
                response.countrySettingsResolve.countrySettings;
            this.kipoLimits = response.kipoLimits.data;

            this._PayPalAccount = response.originAccounts?.paypalAccounts
                ? response.originAccounts?.paypalAccounts[0]
                : null;
            this._PrepaidAccount = response.originAccounts?.prepaidAccounts
                ? response.originAccounts?.prepaidAccounts[0]
                : null;

            this.globalService.setPaypalAccount(this._PayPalAccount);
        });

        this.kipoService.userUpdated().subscribe(() => {
            this.showAlerts();
        });

        this.globalService.getIbansAccountList().subscribe((accounts: any) => {
            if (accounts?.length === 0) {
                this.showAlertPendingAccount = true;
            } else {
                this.showAlertPendingAccount = false;
                this.showWithdraw = true;
            }
            this.showAlerts();
        });
    }

    ngOnInit() {
        this.kipoService.getUserData().subscribe((response: any) => {
            this.globalService.setProfile(response);
            this._userProfile = response;
            this.showAlerts();
            this.doInitData();
            this.setLimits();
        });

        if (window.screen.width >= 768 && window.screen.width <= 1024) {
            this._viewInTabletMode = true;
        } else if (window.screen.width <= 768) {
            this._viewInMobileMode = true;
        } else {
            this._viewInMobileMode = false;
        }

        this._withdrawForm = new UntypedFormGroup({
            amount: new UntypedFormControl('', [Validators.required]),
            sinpeAccountId: new UntypedFormControl('', [Validators.required]),
            paypalAccountId: new UntypedFormControl('', [Validators.required]),
        });

        this.observablesData();

        this.kipoService.getCountryContent().subscribe((template: any) => {
            this.template = template;
        });

        this.label = this.translate('prepaidCard.notAllowed');
    }

    setLimits() {
        this._KipoLimits = this.kipoLimits?.find((item) => {
            return item.productType === this.productType;
        });
    }

    showAlerts() {
        this.showAlertReview = false;
        this.showAlertRejected = false;
        this.showAlertCompleteKyc = false;
        this.showAlertInactive = false;
        this.showWithdraw = true;
        if (!this.showAlertPendingAccount) {
            this._userProfile = this.globalService.getProfile();
            const userStatus = this._userProfile.status;
            const userEnableToTransact = this._userProfile.enableToTransact;
            this.evaluateShowAlerts(userStatus, userEnableToTransact);
        }
    }

    evaluateShowAlerts(userStatus: string, userEnableToTransact: string) {
        if (userEnableToTransact === UserEnableTransact.NO_ENABLE) {
            if (userStatus === UserStatusesEnum.REG) {
                this.showAlertCompleteKyc = true;
            } else if (
                userStatus === UserStatusesEnum.REV ||
                userStatus === UserStatusesEnum.RVS ||
                userStatus === UserStatusesEnum.COM
            ) {
                this.showAlertReview = true;
            } else if (
                userStatus === UserStatusesEnum.DEN ||
                userStatus === UserStatusesEnum.REJ
            ) {
                this.showAlertRejected = true;
            }
        } else if (userEnableToTransact === UserEnableTransact.INACTIVE) {
            this.showAlertInactive = true;
        }
    }

    observablesData() {
        this.kipoService.getIssuers().subscribe((data) => {
            this.issuers = data;
        });
        this.globalService.getIbansAccountList().subscribe(
            (_ibansResult: any) => {
                this.manageAccountList(_ibansResult as [any]);
            },
            (err: any) => {
                this.globalService.errorMessage(
                    err.error.message || err.message,
                );
            },
        );
    }

    manageAccountList(sinpeList: [any]) {
        this._SinpeAccounts = sinpeList;

        if (!this._SinpeAccounts) {
            return;
        }

        const sinpeActiv = sinpeList.filter((item) => item.status === 'ACTIV');

        sinpeList.sort((a, b) => {
            if (a.account === this._PrepaidAccount?.payerId) {
                return -1;
            } else if (b.account === this._PrepaidAccount?.payerId) {
                return 1;
            }

            if (a.trxnRealTime === b.trxnRealTime) {
                return 0;
            } else if (a.trxnRealTime) {
                return -1;
            } else {
                return 1;
            }
        });
        if (sinpeActiv && sinpeActiv.length === 1) {
            this._SelectSinpe = sinpeActiv[0];

            this._withdrawForm.patchValue({
                sinpeAccountId: this._SelectSinpe.accountId,
            });
        }
    }

    ngAfterViewInit() {
        this.actRouter.queryParams.subscribe(
            (params: any) => {
                const kipoErrorsArray: string[] = Object.keys(KipoError);
                if (
                    params.code &&
                    kipoErrorsArray.findIndex((x) => x === params.code) >= 0
                ) {
                    this._formLoadError = params.code || '';
                    this.dialog.open(DialogComponent, {
                        maxWidth: '500px',
                        data: {
                            code: this._formLoadError,
                        },
                    });
                } else if (params.message) {
                    this.globalService.errorMessage(params.message);
                }
            },
            (err: any) => {
                this.globalService.errorMessage(
                    err.error.message || err.message,
                );
            },
        );

        this._sinpeCards?.selectionChange.subscribe(
            (itemSelection: MatSelectionListChange) => {
                this._sinpeCards.deselectAll();
                itemSelection.options[0].selected = true;
            },
            (err: any) => {
                this.globalService.errorMessage(
                    err.error.message || err.message,
                );
            },
        );
    }

    doInitData() {
        this.getPaypalBalance();
        this.getDestinationAccounts();
        this.getProductType();
    }

    getProductType() {
        this.globalService.getOriginAccountType().subscribe(
            (productType: string) => {
                this.productType = productType;
                this.setLimits();
                this.setWithdrawFormLimit();
                const accountId =
                    productType === PRODUCT_TYPES.PAYPAL
                        ? this._PayPalAccount?.accountId
                        : this._PrepaidAccount?.accountId;

                this._withdrawForm.patchValue({
                    paypalAccountId: accountId,
                });

                this.validateDestinationAccounts();
            },
            (err: any) => {
                this.globalService.errorMessage(
                    err.error.message || err.message,
                );
            },
        );
    }

    private validateDestinationAccounts() {
        if (this.productType === PRODUCT_TYPES.PREPAID) {
            this._SinpeAccounts = this._SinpeAccounts.map((item) => {
                if (item.account === this._PrepaidAccount?.payerId) {
                    item.isDisabled = true;
                }
                if (
                    this._SelectSinpe?.account === this._PrepaidAccount?.payerId
                ) {
                    this._SelectSinpe = '';
                    this._withdrawForm.patchValue({
                        sinpeAccountId: '',
                    });
                }

                return item;
            });
        } else {
            this._SinpeAccounts = this._SinpeAccounts.map((item) => {
                item.isDisabled = false;
                return item;
            });
        }
    }

    private getPaypalBalance() {
        if (this._PayPalAccount) {
            forkJoin([
                this.kipoService
                    .getBalance(this._PayPalAccount.sessionToken)
                    .pipe(
                        (res) => res,
                        catchError((e) => of(null)),
                    ),
            ]).subscribe(
                (result) => {
                    if (result[0]) {
                        this._PayPalBalance = result[0];
                        this._withdrawForm.patchValue({
                            paypalAccountId: this._PayPalAccount.accountId,
                        });

                        this.setWithdrawFormLimit();
                    }
                    this.isLoading = false;
                },
                (err) => {
                    this._formLoadError = err.code || '';
                    const errorMessage =
                        err.error.message ||
                        this.translate('withdraw.transactionFailGetBalance');
                    this.globalService.errorMessage(errorMessage);
                },
            );
        }
    }

    private getDestinationAccounts() {
        forkJoin([
            this.kipoService
                .getDestinationAccountsByUser(this._userProfile.userId)
                .pipe(
                    (res) => res,
                    catchError((e) => of(null)),
                ),
            this.kipoService.getKipoFees().pipe(
                (res) => res,
                catchError((e) => of(null)),
            ),
        ]).subscribe(
            (result) => {
                this._formLoadError = this._formLoadError || null;
                this.manageAccountList(result[0] as [any]);
                this.globalService.setIbanAccountsList(result[0]);

                if (result[1]) {
                    this._taxes = result[1]?.ITBMS || result[1]?.IVA;
                }
            },
            (err) => {
                this._formLoadError = err.code || '';
                const errorMessage =
                    err.error.message ||
                    `Problemas con la carga de información
                    del usuario, por favor intente dentro de un momento.`;
                this.globalService.errorMessage(errorMessage);
            },
        );
    }

    private setWithdrawFormLimit() {
        let availableAccount = 0;
        if (this.productType === PRODUCT_TYPES.PREPAID) {
            const prepaidBalance = this.globalService.getPrepaidBalance();
            availableAccount = prepaidBalance;
        } else {
            availableAccount = this._PayPalBalance?.available?.value;
        }

        const { dayLimit, minAmount } = this._KipoLimits;

        const maxTransaction =
            availableAccount < dayLimit ? availableAccount : dayLimit;

        this._withdrawForm.controls['amount'].setValidators([
            Validators.required,
            Validators.min(minAmount),
            Validators.max(maxTransaction),
        ]);
    }

    addAccount() {
        const dialogRef = this.dialog.open(DestinationAccountComponent, {
            data: { profile: this._userProfile },
            panelClass: 'custom-modalbox',
        });
        dialogRef.afterClosed().subscribe(() => {
            this.doInitData();
        });
    }

    stepperBack() {
        this._stepper.previous();
    }

    goToConfirmStep() {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        tomorrow.toLocaleDateString();
        this._issuerName = this.issuers.filter((item) => {
            return item.code == this._SelectSinpe.issuerId;
        })[0]?.description;

        this._transaction = {
            paypalAccount:
                this._PayPalAccount?.email || this._userProfile.username,
            sinpeAccount: this._SelectSinpe.account,
            amount: this.calculatorData,
            date: today,
            liquidationDate: tomorrow,
            realTime: this._SelectSinpe.trxnRealTime || false,
        };
        this._stepper.next();
    }

    saveWithdraw() {
        if (this._withdrawForm.invalid) {
            return;
        }

        const payload = {
            ...this._withdrawForm.value,
        };
        payload.amount = this.getRoundNumber(payload.amount);
        this.isLoading = true;
        this.kipoService.postPaypalWithdraw(payload).subscribe(
            (withdrawResult) => {
                if (withdrawResult) {
                    this._transaction['withdrawResult'] = withdrawResult;
                    this.doInitData();
                    this.updateUserData();
                    this._stepper.next();
                }
            },
            (err) => {
                let errorMsg: string = '';
                let kipoLimit: string = '';
                switch (err?.error?.error) {
                    case LimitStatusError.dayLimitExceeded:
                        kipoLimit = this._KipoLimits.dayLimit;
                        break;
                    case LimitStatusError.weekLimitExceeded:
                        kipoLimit = this._KipoLimits.weekLimit;
                        break;
                    case LimitStatusError.monthLimitExceeded:
                        kipoLimit = this._KipoLimits.monthLimit;
                        break;
                }
                errorMsg = err.error?.message?.replace('%s', kipoLimit);
                if (errorMsg) {
                    this.globalService.errorMessage(errorMsg);
                } else {
                    this.globalService.errorMessage(
                        'Problemas al realizar tu transacción, por favor intentalo más tarde.',
                    );
                }
                this.isLoading = false;
            },
        );
    }

    setSelectedSinpe(inSinpe: any) {
        if (inSinpe.status === 'ACTIV') {
            this._SelectSinpe = inSinpe;
            this._withdrawForm.patchValue({
                sinpeAccountId: inSinpe.accountId,
            });
        } else {
            this._SelectSinpe = '';
            this._withdrawForm.patchValue({
                sinpeAccountId: '',
            });
        }
    }

    getAmountValue(value: any) {
        this.calculatorData = value;
        this._withdrawForm.get('amount')?.patchValue(value.amount);
    }

    public printVoucher() {
        if (window.matchMedia('only screen and (max-width: 768px)').matches) {
            window.onbeforeprint = function () {
                document.body.classList.add('mobile-print');
            };
            window.onafterprint = function () {
                document.body.classList.remove('mobile-print');
            };
        }

        window.print();
    }

    public resetComponent() {
        this.showAlerts();
        this.calculator.resetForm();
        this._sinpeCards.deselectAll();
        this._withdrawForm.reset();
        this._PayPalBalance = {} as any;
        this._SelectSinpe = {} as any;
        this._SinpeAccounts = [];
        this._showAddIbanForm = false;
        this._formLoadError = null;
        this._transaction = {};
        this._netAmount = 0;
        this._netFees = 0;
        this._IVAFee = 0;
        this.doInitData();
        this._stepper.reset();
    }

    public editAccount(account: destinationAccount) {
        this._destinationAccount = account;
        const dialogRef = this.dialog.open(DestinationAccountComponent, {
            data: { account, profile: this._userProfile },
            panelClass: 'custom-modalbox',
        });
        dialogRef.afterClosed().subscribe(() => {
            this.kipoService
                .getDestinationAccountsByUser(this._userProfile.userId)
                .subscribe((result) => {
                    this.manageAccountList(result as [any]);
                    this.cdr.detectChanges();
                });
        });
    }

    public deleteIban(sinpeCard: any) {
        this.kipoService.deleteAccount(sinpeCard).subscribe(
            (resultDelete: any) => {
                this.doInitData();
            },
            (err) => {
                this.globalService.errorMessage(
                    err.error.message || err.message,
                );
            },
        );
    }

    private getRoundNumber(amount: string | number) {
        if (typeof amount === 'string') {
            amount = parseFloat(amount);
        }
        return Math.round(amount * 100 + Number.EPSILON) / 100;
    }

    updateUserData() {
        this.kipoService.getUserData().subscribe((userInfo) => {
            if (userInfo) {
                this.globalService.setProfile(userInfo);
            }
        });

        this.showAlerts();
    }

    public handleCardValue(value: boolean) {
        this._card = value;
    }

    public handleFeeValue(event: any) {
        this.accumulateFeeValue = event;
    }

    public handleInactivateAccount(sinpeAcc: any) {
        this._SelectSinpe = sinpeAcc;
        this.dialog.open(InactiveDisclaimerComponent, {
            data: this._SelectSinpe,
            maxWidth: '500px',
        });
    }
}
