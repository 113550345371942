import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent {
    @Output() callParentSave = new EventEmitter<null>();
    @Output() callParentBack = new EventEmitter<MatStepper>();
    @Input() stepper!: MatStepper;
    @Input() transaction: any;
    @Input() accumulateFeeValue: any;
    @Input() IVA!: number;
    @Input('isLoading') isLoading: boolean = false;
    public template: any = null;

    callParent(event: any) {
        if (event) {
            if (event.srcElement.id == 'btnBack') {
                this.callParentBack.next(this.stepper);
            } else {
                this.callParentSave.next(null);
            }
        }
    }
}
