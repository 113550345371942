import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { KipoService } from '@core/kipo.service';

@Directive({
    selector: '[appRender]',
})
export class RenderDirective implements OnInit {
    @Input() appRender!: string;
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private kipoFService: KipoService,
    ) {}

    ngOnInit() {
        this.kipoFService.getCountryContent().subscribe((content) => {
            const values = Object.keys(content);
            if (values.includes(this.appRender)) {
                this.viewContainerRef.createEmbeddedView(this.templateRef);
                return;
            }
            this.viewContainerRef.clear();
        });
    }
}
