import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
    selector: 'app-voucher',
    templateUrl: './voucher.component.html',
    styleUrls: ['./voucher.component.scss'],
})
export class VoucherComponent {
    @Output() callPrintParent = new EventEmitter<null>();
    @Output() resetComponent = new EventEmitter<null>();

    @Input() transaction: any;
    @Input() issuerName: any;
    @Input() IVA!: number;
    @Input() accumulateFeeValue: any;

    callParentPrint() {
        this.callPrintParent.emit();
    }

    resetStepper() {
        window.location.reload();
    }
}
