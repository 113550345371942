<div
    class="d-flex justify-content-center align-items-center height d-flex flex-column height"
>
    <mat-icon class="logo-icon text"> cancel</mat-icon>
    <p class="font-weight-bold text h5">
        {{ 'withdraw.alertInactiveUser.title' | translate }} <br /><br />
        {{ 'withdraw.alertInactiveUser.message' | translate }}
        <a
            href="{{ 'withdraw.alertInactiveUser.link' | translate }}"
            target="_blank"
            rel="noopener"
            >{{ 'withdraw.alertInactiveUser.linkTitle' | translate }}</a
        ><br /><br />
        {{ 'withdraw.alertInactiveUser.footer' | translate }}
    </p>
</div>
