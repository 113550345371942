import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
    FormControl,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { KipoService } from '@core/kipo.service';
import { ADDRESS_TYPE_CODE } from '@enums/address-types.enum';
import { clientTypesEnum } from '@enums/identificationTypes.enum';
import { BaseComponent, BootService } from '@impesa/ngx-core';
import { KycService } from '@impesa/ngx-kyc';
import { City } from '@impesa/ngx-kyc/lib/models/city/city.model';
import { State } from '@impesa/ngx-kyc/lib/models/profile/profile.model';
import { PrepaidCard } from '@models/prepaid-card.model';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';
import { PrepaidCardAlertComponent } from '../prepaid-card-alert/prepaid-card-alert.component';
import { GENDER_TYPE } from '@enums/gender-types.enum';

@Component({
    selector: 'app-prepaid-card-form',
    templateUrl: './prepaid-card-form.component.html',
    styleUrls: ['./prepaid-card-form.component.scss'],
})
export class PrepaidCardFormComponent extends BaseComponent implements OnInit {
    public _userProfile!: any;
    public postPrepaidInfo!: PrepaidCard;
    public postPrepaidJuridicInfo!: PrepaidCard;
    public prepaidForm!: UntypedFormGroup;
    public userCountry: any;
    public userState: any;
    public userCity: string = '';
    public states!: State[];
    public cities!: City[];
    public districts!: any;
    public personProfile!: any;
    public juridicProfile!: any;
    public identificationType!: string;
    public showMessage: boolean = false;
    public isPerson: boolean = false;
    public isMobile: boolean = false;
    public dynamicLabel: any;
    public currentDate = new Date();
    public maxDate!: Date;
    public showIcon: boolean = false;
    public selectedAddressType = ADDRESS_TYPE_CODE.HOME;
    public selectedGenderType = GENDER_TYPE.MALE;
    public buttonTouched: boolean = false;

    addressTypesList: ADDRESS_TYPE_CODE[] = [
        ADDRESS_TYPE_CODE.HOME,
        ADDRESS_TYPE_CODE.JOB,
    ];

    genderTypesList: GENDER_TYPE[] = [GENDER_TYPE.MALE, GENDER_TYPE.FEMALE];

    constructor(
        boot: BootService,
        public dialog: MatDialog,
        private kipoService: KipoService,
        private kycService: KycService,
        private globalService: GlobalVarsService,
        private datePipe: DatePipe,
        public dialogRef: MatDialogRef<PrepaidCardAlertComponent>,
    ) {
        super(boot);
    }

    ngOnInit() {
        this._userProfile = this.globalService.getProfile();

        if (this._userProfile?.clientType === clientTypesEnum.PERSON) {
            this.kycService
                .getKycPerson(this._userProfile?.identificationNumber)
                .subscribe((response: any) => {
                    this.personProfile = response;
                });
            const tenYearsAgo = new Date();
            tenYearsAgo.setFullYear(this.currentDate.getFullYear() - 10);
            this.maxDate = tenYearsAgo;
        } else {
            this.kycService
                .getKycBusiness(this._userProfile?.identificationNumber)
                .subscribe((response: any) => {
                    this.juridicProfile = response;
                });
            const oneYearAgo = new Date();
            oneYearAgo.setFullYear(this.currentDate.getFullYear() - 1);
            this.maxDate = oneYearAgo;
        }

        this.kycService.getIdentificationTypes().subscribe((response: any) => {
            this.identificationType = response.find(
                (idType: any) =>
                    idType.code === this._userProfile?.identificationType,
            ).ref1;
        });

        this.kycService.getCountries().subscribe((response: any) => {
            this.userCountry = response.find(
                (country: any) => country.iso3 === this._userProfile?.country,
            );
            this.prepaidForm.controls['nationality'].setValue(
                this.userCountry.name,
            );
            this.prepaidForm.controls['nationality'].disable();
            this.getStates();
        });

        this.userIsPerson();
        this.createFormPrepaid();

        if (window.screen.width <= 768) {
            this.isMobile = true;
        }
    }

    createFormPrepaid() {
        this.prepaidForm = this.formBuilder.group({
            fullName: new UntypedFormControl({
                value: this._userProfile?.fullName || '',
                disabled: true,
            }),
            address: new UntypedFormControl(''),
            nationality: new UntypedFormControl(''),
            birthDate: new UntypedFormControl(''),
            canton: new UntypedFormControl(''),
            state: new UntypedFormControl(''),
            district: new UntypedFormControl(''),
            addressType: new UntypedFormControl(this.selectedAddressType || ''),
            phone: new FormControl(this._userProfile?.phoneNumber, [
                Validators.pattern('^.{8}$'),
            ]),
            gender: new UntypedFormControl(this.selectedGenderType || ''),
        });
    }
    userIsPerson(): any {
        if (this._userProfile?.clientType === clientTypesEnum.PERSON) {
            this.dynamicLabel =
                this.translate('prepaidCard.birthDate') +
                ' ' +
                this.translate('prepaidCard.birth');
            this.isPerson = true;
        } else {
            this.dynamicLabel =
                this.translate('prepaidCard.birthDate') +
                ' ' +
                this.translate('prepaidCard.constitutionDate');
            return this.dynamicLabel;
        }
    }

    savePrepaidInfo() {
        this.buttonTouched = true;
        if (this._userProfile.clientType === clientTypesEnum.BUSINESS) {
            return this.savePrepaidInfoJuridic();
        }

        let dateValue = this.datePipe.transform(
            this.prepaidForm?.controls?.birthDate?.value,
            'yyyy-MM-dd',
        );
        const date = dateValue ?? '';
        let fullName = this.prepaidForm?.controls.fullName.value;
        const maxLength = 21;
        const name =
            fullName.length > maxLength
                ? fullName.substring(0, maxLength)
                : fullName;
        this.postPrepaidInfo = {
            name: name,
            person: {
                identification: this.personProfile?.identification,
                identificationType: this.identificationType?.toString(),
                personType: this.personProfile?.clientType,
                firstName: this.personProfile?.firstName1,
                middleName: this.personProfile?.firstName2,
                lastName: this.personProfile?.surname1,
                surname: this.personProfile?.surname2,
                birth: {
                    date: date,
                    country: this._userProfile.country,
                },
                gender: this.prepaidForm?.controls?.gender?.value,
            },
            address: {
                type: this.prepaidForm?.controls?.addressType?.value,
                countryCode: this._userProfile.country,
                provinceCode: this.prepaidForm?.controls.state?.value,
                cantonCode: this.prepaidForm?.controls.canton?.value,
                districtCode: this.prepaidForm?.controls.district?.value,
                detail: this.prepaidForm?.controls.address.value,
            },
            contact: {
                phone: this.prepaidForm?.controls.phone.value,
                cell: this.prepaidForm?.controls.phone.value,
                email: this.personProfile?.email,
            },
        };
        this.validateForm();
    }

    savePrepaidInfoJuridic() {
        let fullName = this.prepaidForm.controls.fullName.value;
        const maxLength = 21;
        const name =
            fullName.length > maxLength
                ? fullName.substring(0, maxLength)
                : fullName;
        this.postPrepaidJuridicInfo = {
            name: name,
            person: {
                identification: this.juridicProfile?.identification,
                identificationType: this.identificationType?.toString(),
                personType: this.juridicProfile?.clientType,
                corpName: name,
            },

            address: {
                type: ADDRESS_TYPE_CODE.JOB,
                countryCode: this._userProfile.country,
                provinceCode: this.prepaidForm?.controls.state?.value,
                cantonCode: this.prepaidForm?.controls.canton?.value,
                districtCode: this.prepaidForm?.controls.district?.value,
                detail: this.prepaidForm?.controls.address.value,
            },
            contact: {
                phone: this.prepaidForm?.controls.phone.value,
                cell: this.prepaidForm?.controls.phone.value,
                email: this.juridicProfile?.email,
            },
        };

        this.validateForm();
    }

    validateForm() {
        Object.keys(this.prepaidForm.controls).forEach((key) => {
            const control = this.prepaidForm.get(key);
            if (control) {
                control.markAsTouched();

                if (control.value === '' || control.value === null) {
                    control.setValidators([Validators.required]);
                    control.updateValueAndValidity();
                    this.globalService.errorMessage(
                        this.translate('additionalInfoLegal.requiredAlert'),
                    );
                    this.buttonTouched = false;
                } else {
                    control.clearValidators();
                }
            }
        });
        if (this.prepaidForm.valid) {
            this.loading();
            this.kipoService
                .requestPrepaidCard(
                    this.postPrepaidInfo
                        ? this.postPrepaidInfo
                        : this.postPrepaidJuridicInfo,
                )
                .subscribe(
                    (resp) => {
                        this.globalService.setPrepaidRequestInfo(resp);
                        setTimeout(() => {
                            this.showMessage = true;
                        }, 1000);
                    },
                    (err: any) => {
                        this.showMessage = false;
                        this.globalService.errorMessage(
                            err.error.message || err.message,
                        );
                    },
                );
        }
    }

    loading() {
        if (this.showMessage === true) {
            this.showIcon = false;
        } else {
            this.showIcon = true;
        }
    }

    acceptInfo() {
        this.dialogRef.close('formSubmitted');
    }

    getStates() {
        this.kycService.getStates().subscribe((response: any) => {
            this.userState = response.find(
                (state: any) =>
                    state?.id === this.personProfile?.addresses[0].state ||
                    state?.id === this.juridicProfile?.addresses[0].state,
            );
            this.prepaidForm.controls['state'].setValue(this.userState?.id);
            this.states = response;
            if (this.userState?.id) {
                this.getCities(this.userCountry?.iso3, this.userState?.id);
            }
        });
    }

    getCities(country: any, state: any) {
        this.userState = state;
        this.districts = [];
        this.prepaidForm.controls['canton'].setValue('');
        this.prepaidForm.controls['district'].setValue('');

        this.kycService.getCities(country, state).subscribe((response: any) => {
            this.cities = response;
            if (
                state === this.personProfile?.addresses[0].state ||
                state === this.juridicProfile?.addresses[0].state
            ) {
                this.userCity = response.find(
                    (city: any) =>
                        city?.id === this.personProfile?.addresses[0].city ||
                        city?.id === this.juridicProfile?.addresses[0].city,
                ).id;
                this.prepaidForm.controls['canton'].setValue(this.userCity);
            } else {
                this.userCity = this.prepaidForm.controls['canton'].value;
            }
            if (this.userCity) {
                this.getDistricts(state, this.userCity);
            }
        });
    }

    getDistricts(state: any, city: any) {
        this.kipoService.getDistricts(state, city).subscribe((data: any[]) => {
            this.districts = data;
            this.prepaidForm.controls['district'].enable();
        });
    }

    getLabelForAddressType(addressType: ADDRESS_TYPE_CODE): string {
        if (addressType === ADDRESS_TYPE_CODE.HOME) {
            return this.translate('prepaidCard.addressType1');
        } else if (addressType === ADDRESS_TYPE_CODE.JOB) {
            return this.translate('prepaidCard.addressType2');
        }
        return '';
    }
}
