import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { KipoService } from '@core/kipo.service';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';

@Component({
    selector: 'app-nav-notificator',
    templateUrl: './nav-notificator.component.html',
    styleUrls: ['./nav-notificator.component.scss'],
})
export class NavNotificatorComponent {
    @Input() _notifications!: any[];
    @Output() readNotifications: EventEmitter<any> = new EventEmitter();

    constructor(
        private kipoService: KipoService,
        private globalService: GlobalVarsService,
        private router: Router,
    ) {}

    readNotification(notificationId: any) {
        if (notificationId != '') {
            this.kipoService.readNotification(notificationId).subscribe(
                (notification) => {
                    this.readNotifications.emit();
                },

                (err) => {
                    this.globalService.errorMessage(
                        err.error.message || err.message,
                    );
                },
            );
        } else {
            this.readNotifications.emit();
        }
    }

    readUserNotifications() {
        const messages = this._notifications.map((value) => value.id);
        if (messages) {
            this.kipoService.readUserNotifications().subscribe(
                (result) => {
                    this.readNotifications.emit();
                },
                (err: any) => {
                    this.globalService.errorMessage(
                        err.error.message || err.message,
                    );
                },
            );
        }
    }

    validateMessageType(description: string) {
        if (description.toLowerCase().includes('perfil')) {
            this.router.navigate(['app/profile'], {
                queryParams: {
                    completeKyc: true,
                },
            });
        }
    }
}
