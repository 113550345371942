import { Component, OnInit } from '@angular/core';
import { KipoService } from '../../core/kipo.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    public template: any = null;
    constructor(protected readonly kipoFService: KipoService) {}
    ngOnInit(): void {
        this.kipoFService.getCountryContent().subscribe((template: any) => {
            this.template = template;
        });
    }
}
