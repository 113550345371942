<app-prepaid-user-guide *ngIf="showMessage"> </app-prepaid-user-guide>
<div class="prepaid-form col-12" [formGroup]="prepaidForm" *ngIf="!showMessage">
    <div
        class="d-flex"
        [ngClass]="{
            'flex-column': isMobile
        }"
    >
        <label
            for="subTitle"
            class="mt-4 pl-0"
            [ngClass]="{ 'form-title': isMobile }"
        >
            {{ 'prepaidCard.subTitle' | translate }}
        </label>
    </div>
    <div class="row">
        <div class="form-group col-lg-3 mt-2">
            <label for="fullName">
                {{ 'profile.labelFullName' | translate }}
                <i class="indicator-requiered">*</i></label
            >
            <div>
                <input
                    formControlName="fullName"
                    name="fullName"
                    class="form-control"
                    type="text"
                    id="fullName"
                />
            </div>
        </div>
        <div class="col-lg-4 form-group mt-2">
            <label>
                {{ 'profile.labelTelephone' | translate }}
                <i class="indicator-requiered">*</i>
            </label>
            <div class="d-flex">
                <select
                    class="form-control"
                    style="width: 100px; margin-right: 10px"
                    disabled
                >
                    <option selected>
                        {{ 'prepaidCard.countryCodePhone' | translate }}
                    </option>
                </select>
                <input
                    formControlName="phone"
                    type="text"
                    id="phone"
                    class="form-control"
                />
            </div>
            <mat-error
                *ngIf="prepaidForm.get('phone')?.errors?.pattern"
                class="error-message"
            >
                {{ 'prepaidCard.validatePhone' | translate }}
            </mat-error>
        </div>
        <div class="form-group col-lg-3 mt-2 row ml-0">
            <label for="birthDate" appearance="outline">
                {{ dynamicLabel }}
                <i class="indicator-requiered">*</i>
            </label>
            <div class="w-100">
                <div
                    class="d-flex align-items-center position-relative form-group"
                >
                    <input
                        matInput
                        [matDatepicker]="picker"
                        formControlName="birthDate"
                        name="birthDate"
                        class="form-control"
                        type="text"
                        id="birthDate"
                        appearance="outline"
                        [max]="maxDate"
                    />

                    <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                        class="position-absolute mat-datepicker-toggle-custom"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </div>
            </div>
        </div>
        <div class="col-lg-2 form-group mt-2">
            <label
                for="gender"
                [ngClass]="isMobile ? 'd-inline-block text-nowrap' : 'ml-2'"
            >
                {{ 'prepaidCard.genderType' | translate }}
                <i class="indicator-requiered">*</i>
            </label>
            <mat-radio-group class="d-flex pt-1" formControlName="gender">
                <div *ngFor="let item of genderTypesList">
                    <mat-radio-button
                        [value]="item"
                        class="prepaid-card-form-genderType"
                        [id]="item"
                    >
                        {{ item }}
                    </mat-radio-button>
                </div>
            </mat-radio-group>
        </div>
    </div>
    <mat-divider></mat-divider><br />
    <div class="d-flex align-items-end row">
        <label for="subTitle" class="col-lg-4">
            {{ 'prepaidCard.subTitle2' | translate }}
        </label>
        <div
            *ngIf="isPerson"
            [ngClass]="
                isMobile
                    ? ' col-6 d-flex flex-wrap flex-column pl-3 mt-4'
                    : 'form-group col-6 d-flex mb-0 pl-2'
            "
        >
            <label
                for="address"
                [ngClass]="isMobile ? 'd-inline-block text-nowrap' : 'ml-2'"
            >
                {{ 'prepaidCard.addressType' | translate }}
                <i class="indicator-requiered">*</i>
            </label>
            <mat-radio-group class="d-flex pt-1" formControlName="addressType">
                <div
                    [ngClass]="isMobile ? 'col-6 pl-0' : 'col-6 pl-3'"
                    *ngFor="let item of addressTypesList"
                >
                    <mat-radio-button
                        [value]="item"
                        class="prepaid-card-form-selectAddressType"
                        [id]="item"
                    >
                        {{ getLabelForAddressType(item) }}
                    </mat-radio-button>
                </div>
            </mat-radio-group>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-lg-4 mt-2">
            <label for="countries">
                {{ 'prepaidCard.nationality' | translate }}
                <i class="indicator-requiered">*</i></label
            >
            <div>
                <input
                    formControlName="nationality"
                    name="nationality"
                    class="form-control"
                    type="text"
                    id="fullName"
                />
            </div>
        </div>
        <div class="form-group col-lg-4 mt-2">
            <label for="countries">
                {{ 'profile.labelState' | translate }}
                <i class="indicator-requiered">*</i>
            </label>
            <mat-select
                placeholder="Seleccione"
                formControlName="state"
                class="kyc-business-province form-control"
                (selectionChange)="getCities(userCountry.iso3, $event.value)"
            >
                <mat-option *ngFor="let state of states" [value]="state.id">
                    {{ state.name }}
                </mat-option>
            </mat-select>
        </div>

        <div class="form-group col-lg-4 mt-2">
            <label for="countries">
                {{ 'prepaidCard.county' | translate }}
                <i class="indicator-requiered">*</i>
            </label>
            <div>
                <mat-select
                    placeholder="Seleccione"
                    formControlName="canton"
                    class="kyc-business-province form-control"
                    (selectionChange)="getDistricts(userState, $event.value)"
                >
                    <mat-option *ngFor="let city of cities" [value]="city.id">
                        {{ city.name }}
                    </mat-option>
                </mat-select>
            </div>
        </div>

        <div class="form-group col-lg-4 mt-2">
            <label for="countries">
                {{ 'additionalInfoLegal.district' | translate }}
                <i class="indicator-requiered">*</i>
            </label>
            <div>
                <mat-select
                    placeholder="Seleccione"
                    formControlName="district"
                    class="profileForm-selectState form-control"
                >
                    <mat-option
                        *ngFor="let district of districts"
                        [value]="district.id"
                    >
                        {{ district.name }}
                    </mat-option>
                </mat-select>
            </div>
        </div>

        <div class="form-group col-lg-8 mt-2">
            <label for="address">
                {{ 'prepaidCard.labelAddress' | translate }}
                <i class="indicator-requiered">*</i></label
            >
            <div>
                <input
                    formControlName="address"
                    name="address"
                    class="form-control"
                    type="text"
                    id="address"
                />
            </div>
        </div>
    </div>
</div>

<div class="d-flex justify-content-center margin-form">
    <div
        *ngIf="!showMessage"
        class="col-lg-4 col-xl-6 form-group text-center d-flex justify-content-center align-items-end mb-0"
        id="ColFormPrepaidCard"
    >
        <button
            *ngIf="!showMessage"
            class="btn btn-primary text-center button form-group mb-0 d-flex position-relative justify-content-center align-items-center"
            type="button"
            id="SendButtom"
            [disabled]="buttonTouched"
            (click)="savePrepaidInfo()"
        >
            <mat-icon *ngIf="showIcon" class="hourglass-icon mr-1"
                >hourglass_bottom</mat-icon
            >
            <div *ngIf="!showIcon">
                {{ 'membership.send' | translate }}
            </div>
            <div *ngIf="showIcon">{{ 'prepaidCard.sending' | translate }}</div>
        </button>
    </div>
    <div
        *ngIf="showMessage"
        class="form-group text-center d-flex justify-content-center align-items-end mb-0"
        id="ColFormPrepaidCard"
    >
        <button
            *ngIf="showMessage"
            class="btn btn-primary text-center button form-group mb-0 d-flex position-relative justify-content-center align-items-center"
            type="button"
            id="SendButtom"
            (click)="acceptInfo()"
        >
            <div>
                {{ 'prepaidCard.send' | translate }}
            </div>
        </button>
    </div>
</div>
