import { Component, OnInit } from '@angular/core';
import { KipoService } from '@core/kipo.service';
import { AppService, BaseComponent, BootService } from '@impesa/ngx-core';
import { ServerMessageService } from '@shared/services/server.message.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {
    constructor(
        boot: BootService,
        private readonly appService: AppService,
        private readonly kipoService: KipoService,
        private readonly serverMessageService: ServerMessageService,
    ) {
        super(boot);
    }
    ngOnInit() {
        this.appService.nsp = this.kipoService.getCountry();
        this.serverMessageService.listen();
    }
}
