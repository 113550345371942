import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KipoService } from '@core/kipo.service';
import { clientTypesEnum } from '@enums/identificationTypes.enum';
import { ClientType } from '@models/client-type';
import { CountrySettings } from '@models/settings/country-settings.interface';
import { GlobalVarsService } from '../../shared/services/global-vars/global-vars.service';
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
    public _identificationType!: string;
    _billingIdType!: string;

    getIdNumberType: boolean = false;

    public tabDefaultPosition: boolean = true;

    public showAttachment: boolean = false;
    public showAlert: boolean = false;
    public showBilling: boolean = true;

    public countrySettings!: CountrySettings;
    public template: any;

    idNumberType!: ClientType;
    masket!: string;

    profileForm: UntypedFormGroup = new UntypedFormGroup({});
    billingForm: UntypedFormGroup = new UntypedFormGroup({});

    billingClass: any;
    postBillingClass: any;
    userProfile!: any;
    patchUserProfile!: any;
    public clientTypesList: any;
    public disableBillingValue!: boolean;
    public clientType: string = '';
    private completeKyc!: string;

    selected = new UntypedFormControl(0);

    public showProfile = false;
    public showKyc = false;
    public readOnly = true;
    public isCustomBilling = true;
    public isMobile: boolean = window.innerWidth < 768;

    constructor(
        private router: Router,
        private kipoService: KipoService,
        private globalService: GlobalVarsService,
        private route: ActivatedRoute,
        private readonly activatedRoute: ActivatedRoute,
    ) {
        this.activatedRoute.data.subscribe((response: any) => {
            this.readOnly = response.data?.kycData?.readOnly;

            this.countrySettings =
                response.countrySettingsResolve.countrySettings;
        });
        this.kipoService.getCountryContent().subscribe((template: any) => {
            this.template = template;
        });
    }

    ngOnInit() {
        this.route.queryParamMap.subscribe(
            (params: any) => {
                this.completeKyc = params.get('completeKyc') || '';
            },

            (err: any) => {
                this.globalService.errorMessage(
                    err.error.message || err.message,
                );
            },
        );

        this.userProfile = this.globalService.getProfile();
        if (this.userProfile?.identificationNumber) {
            this.isCustomBilling = false;
        }

        this.validateShowForms();
        this.initProfileChangesEvent();
        if (!this.userProfile) {
            this.showAlert = true;
        }
        if (!this.userProfile?.identificationType) {
            this.getIdNumberType = true;
        }
        this.clientType = this.userProfile?.clientType;
        this.kipoService.getClientTypes().subscribe(
            (result: ClientType[]) => {
                this.clientTypesList = result;
            },
            (err) => {
                this.globalService.errorMessage(
                    err.error.message || err.message,
                );
            },
        );

        this.billingClass = this.globalService.getBilling();
    }

    showAlertPanels(value: boolean) {
        this.showAlert = value;
    }

    async validateShowForms(clientType?: string) {
        this.showKyc = false;
        switch (this.userProfile?.clientType || clientType) {
            case clientTypesEnum.BUSINESS:
                this.showProfile = this.countrySettings.showBusinessProfile;

                if (this.showProfile) {
                    if (this.isBasicFormCompleted()) {
                        this.showKyc = this.countrySettings.showBusinessKyc;
                    }
                } else {
                    this.showKyc = this.countrySettings.showBusinessKyc;
                }
                break;

            case clientTypesEnum.PERSON:
                this.showProfile = this.countrySettings.showPersonProfile;

                if (this.showProfile) {
                    if (this.isBasicFormCompleted()) {
                        this.showKyc = this.countrySettings.showPersonKyc;
                    }
                } else {
                    this.showKyc = this.countrySettings.showPersonKyc;
                }
                break;
            default:
                break;
        }
        if (this.completeKyc) {
            this.selected.setValue(1);
        }
    }

    initProfileChangesEvent() {
        this.globalService.getUserProfile().subscribe(
            (profile: any) => {
                this.validateShowForms();
            },
            (err: any) => {
                this.globalService.errorMessage(
                    err.error.message || err.message,
                );
            },
        );
    }

    isBasicFormCompleted(profile?: any): boolean {
        this.userProfile = profile || this.globalService.getProfile();
        if (this.userProfile?.identificationType) {
            return true;
        }
        return false;
    }

    async getCountTransactionHistory() {
        let totalTransaction: number = 0;
        this.kipoService.getTransactionsHistory().subscribe((transactions) => {
            if (transactions) {
                totalTransaction = transactions.transactions.length;
            }
        });

        return totalTransaction;
    }

    billingGroup() {
        return this.profileForm.get('billing') as UntypedFormGroup;
    }

    radioChange(item: any) {
        this.idNumberType = item;
        this.clientType = item.code;
    }

    saveIdNumberType() {
        this.getIdNumberType = false;
        if (this.idNumberType.code === clientTypesEnum.BUSINESS) {
            this.validateShowForms(clientTypesEnum.BUSINESS);
        }

        if (this.idNumberType.code === clientTypesEnum.PERSON) {
            this.validateShowForms(clientTypesEnum.PERSON);
        }
    }

    validateSave() {
        if (this.profileForm.get('useInBilling')?.value) {
            return !this.profileForm.valid;
        }
        return !(this.profileForm.valid && this.billingForm.valid);
    }

    disableBilling(e: any) {
        this.disableBillingValue = e;
    }

    tabChanged(tabChangeEvent: any) {
        this.tabDefaultPosition = false;
        if (this.isBusiness()) {
            return this.tabDefaultPosition;
        }
        this.tabDefaultPosition = true;
        return this.tabDefaultPosition;
    }

    saveKycBusinessEvent(kyc: any) {
        if (kyc) {
            const access_token = this.globalService.getToken();
            this.kipoService
                .getUserData(access_token)
                .subscribe((_userInfo) => {
                    if (_userInfo) {
                        this.globalService.setProfile(_userInfo);
                    }
                    this.router.navigate(['/app/withdraw']);
                });
        }
    }

    public isPerson() {
        return (
            this.idNumberType?.code === clientTypesEnum.PERSON ||
            this.userProfile?.clientType === clientTypesEnum.PERSON
        );
    }

    public isBusiness() {
        return (
            this.idNumberType?.code === clientTypesEnum.BUSINESS ||
            this.userProfile?.clientType === clientTypesEnum.BUSINESS
        );
    }
}
