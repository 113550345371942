import { Component, Inject, Input } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';

@Component({
    selector: 'app-prepaid-card-alert',
    templateUrl: './prepaid-card-alert.component.html',
    styleUrls: ['./prepaid-card-alert.component.scss'],
})
export class PrepaidCardAlertComponent {
    @Input() showForm: boolean = false;
    public _userProfile!: any;
    isMobile = false;

    constructor(
        public dialogRef: MatDialogRef<PrepaidCardAlertComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
    ) {}

    ngOnInit() {
        if (window.screen.width <= 768) {
            this.isMobile = true;
        }
    }

    addPrepaidCard() {
        this.showForm = true;
    }

    dontShowPopup() {
        localStorage.setItem('showPrepaidPopup', 'false');
        this.dialogRef.close();
    }
}
