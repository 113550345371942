<mat-card
    appearance="outlined"
    class="disclaimer-card"
    *ngIf="environment.disclaimer && showDisclaimer"
>
    <mat-card-header>
        <div mat-card-avatar class="disclaimer-icon">
            <span class="material-icons"> warning </span>
        </div>

        <mat-card-title>AVISOS IMPORTANTES</mat-card-title>
    </mat-card-header>

    <div *ngFor="let notice of notices">
        <mat-card-content>
            <span>
                {{ notice.message }}
            </span>
        </mat-card-content>
        <mat-divider></mat-divider><br />
    </div>
</mat-card>
<br />
