import { Injectable } from '@angular/core';
import { KipoService } from '@core/kipo.service';
import { NotificationTypesEnum } from '@enums/notification-types.enum';
import { environment } from '@environment/environment';
import { SessionPropsService } from '@impesa/ngx-core';
import { destinationAccountResponse } from '@models/destination-account/dtos/destination-account';
import { Notification } from '@models/notifications';
import { io } from 'socket.io-client';
import { GlobalVarsService } from './global-vars/global-vars.service';

@Injectable({ providedIn: 'root' })
export class ServerMessageService {
    constructor(
        private readonly sessionPropsService: SessionPropsService,
        private readonly globalVarsService: GlobalVarsService,
        private readonly kipoService: KipoService,
    ) {}

    public listen() {
        this.sessionPropsService.sessionSubject.subscribe((session: any) => {
            if (session?.access_token) {
                const applicationId = session.access_token.aid;
                const userId = session.access_token.sub;
                const notifySocketURL = [
                    environment.apis.server_message.url,
                    '?applicationId=',
                    applicationId,
                    '&userId=',
                    userId,
                ].join('');
                const socket = io(notifySocketURL);
                const event = environment.apis.server_message.event;
                const error_event = environment.apis.server_message.error_event;

                this.listenSocket(socket, event, userId);
                this.listenSocket(socket, error_event, userId);
            }
        });
    }

    private listenSocket(socket: any, event: any, userId: string) {
        socket.on(event, (receivedMessage: any) => {
            const message: Notification = receivedMessage;

            if (
                message.type != NotificationTypesEnum.KYC ||
                message.showAlert
            ) {
                this.globalVarsService.addNotification(message);
                if (event === environment.apis.server_message.event) {
                    this.globalVarsService.showSuccessMessage(
                        receivedMessage.description,
                    );
                } else {
                    this.globalVarsService.errorMessage(
                        receivedMessage.description,
                    );
                }
            }
            switch (message.type) {
                case NotificationTypesEnum.DESTINATION_ACCOUNT:
                    this.kipoService
                        .getDestinationAccountsByUser(userId)
                        .subscribe((result: destinationAccountResponse[]) => {
                            this.globalVarsService.setIbanAccountsList(result);
                        });
                    break;
                case NotificationTypesEnum.KYC:
                    this.kipoService.getUserData().subscribe((userInfo) => {
                        if (userInfo) {
                            this.globalVarsService.setProfile(userInfo);
                            this.kipoService.updateUser(userInfo);
                        }
                    });
                    break;
            }
        });
    }
}
