import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { KipoService } from '@core/kipo.service';
import { Observable } from 'rxjs/internal/Observable';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

@Injectable({
    providedIn: 'root',
})
export class KipoLimitsResolver {
    constructor(private readonly kipoService: KipoService) {}

    resolve(_route: ActivatedRouteSnapshot): Observable<any> {
        const obsArg = {
            data: this.kipoService.getKipoLimits(),
        };

        return forkJoin(obsArg);
    }
}
