import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KipoService } from '@core/kipo.service';
import { AppService, BaseComponent, BootService } from '@impesa/ngx-core';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-callback',
    templateUrl: './callback.component.html',
    styleUrls: ['./callback.component.scss'],
})
@Injectable()
export class AuthCallbackComponent extends BaseComponent implements OnInit {
    public verificationAlert = false;
    public message!: string;

    constructor(
        boot: BootService,
        private actRouter: ActivatedRoute,
        private globals: GlobalVarsService,
        private kipoService: KipoService,
        private readonly appService: AppService,
    ) {
        super(boot);
    }

    ngOnInit() {
        this.actRouter.queryParams.subscribe((result) => {
            if (result.access_token) {
                this.sessionPropsService.sessionSubject.subscribe((res) => {
                    const currentCountry = localStorage.getItem('country');
                    if (currentCountry !== res.access_token.country) {
                        localStorage.setItem(
                            'country',
                            res.access_token.country,
                        );
                        localStorage.setItem(
                            'namespace',
                            res.access_token.country.toLowerCase(),
                        );
                        window.location.reload();
                        return;
                    }

                    this.appService.nsp = res.access_token.country;
                });
                this.globals.addCookie(result.access_token);
                this.sessionPropsService.session = {
                    access_token: result.access_token,
                    refresh_token: result.refresh_token,
                };

                forkJoin([
                    this.kipoService.getUserData(result.access_token),
                    this.kipoService.getPrepaidRequestStatus(),
                ]).subscribe(([userInfo, prepaidRequestInfo]) => {
                    this.globals.setPrepaidRequestInfo(prepaidRequestInfo);

                    if (userInfo) {
                        this.globals.setProfile(userInfo);
                        this.router.navigate(['app/withdraw']);
                    }

                    if (!userInfo) {
                        this.router.navigate(['app/profile']);
                    }
                });
            }

            if (result.status === 'PND') {
                this.verificationAlert = true;
            }

            if (result.token) {
                localStorage.setItem('access_token', result.token);
                this.kipoService.verifyAccount(result.token).subscribe(
                    (response) => {
                        window.location.href = [
                            window.location.origin,
                            '/authorization/callback',
                            '?access_token=',
                            response.access_token,
                            '&refresh_token=',
                            response.refresh_token,
                        ].join('');
                    },
                    (err: any) => {
                        this.verificationAlert = true;
                        this.message = err.error.message;
                    },
                );
            }
        });
    }
}
