import { Component } from '@angular/core';
import { BaseComponent, BootService } from '@impesa/ngx-core';

@Component({
    selector: 'app-private',
    templateUrl: './private.component.html',
    styleUrls: ['./private.component.scss'],
})
export class PrivateComponent extends BaseComponent {
    constructor(boot: BootService) {
        super(boot);
    }
}
