import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KipoService } from '@core/kipo.service';
import { UpdateDestinationAccountStatusDto } from '@models/destination-account/dtos/destination-account';
import { statusesEnum } from '@enums/destination-accounts-status.enum';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';

@Component({
    selector: 'app-inactive-disclaimer',
    templateUrl: './inactive-disclaimer.component.html',
    styleUrls: ['./inactive-disclaimer.component.scss'],
})
export class InactiveDisclaimerComponent {
    constructor(
        private kipoService: KipoService,
        private globalService: GlobalVarsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<InactiveDisclaimerComponent>,
    ) {}

    inactiveAccount() {
        const updateDestinationAccountStatusDto: UpdateDestinationAccountStatusDto =
            { status: statusesEnum.INACT };
        this.kipoService
            .updateDestinationAccountStatus(
                this.data.accountId,
                updateDestinationAccountStatusDto,
            )
            .subscribe(
                () => {},
                (err: any) => {
                    this.globalService.errorMessage(
                        err.error.message || err.message,
                    );
                },
            );
    }

    onButtonClick() {
        this.inactiveAccount();
        this.close();
    }

    close() {
        this.dialogRef.close();
    }
}
