import {
    Component,
    EventEmitter,
    Inject,
    Input,
    LOCALE_ID,
    OnInit,
    Output,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { PRODUCT_TYPES } from '@enums/product-types.enum';
import { GlobalVarsService } from '@shared/services/global-vars/global-vars.service';
import { KipoService } from '../../../core/kipo.service';

@Component({
    selector: 'app-calculator-form',
    templateUrl: './calculator-form.component.html',
    styleUrls: ['./calculator-form.component.scss'],
})
export class CalculatorFormComponent implements OnInit {
    @Input('position') position?: string;
    @Input('isAmountEnable') isAmountEnable!: boolean;
    @Output() valueChange = new EventEmitter();

    public template: any = null;

    public calculationForm: UntypedFormGroup = new UntypedFormGroup({});
    @Output() accumulateFeeEvent = new EventEmitter<string>();
    public accumulateFeeValue: any;

    public userFees!: any;
    public productType!: string;

    constructor(
        protected readonly kipoFService: KipoService,
        private formBuilder: UntypedFormBuilder,
        private globalService: GlobalVarsService,
        @Inject(LOCALE_ID) protected localeId: string,
    ) {}

    ngOnInit(): void {
        this.calculationForm = this.formBuilder.group({
            amount: new UntypedFormControl(null, [
                Validators.required,
                Validators.pattern(/^-?(0|[1-9]\d*)?$/),
            ]),
            finalAmount: new UntypedFormControl(
                { value: '$0', disabled: true },
                Validators.required,
            ),
        });
        this.kipoFService.getCountryContent().subscribe(
            (template: any) => {
                this.template = template;
                for (const control of [
                    ...(template?.calculator?.fees || {}),
                    ...(template?.calculator?.taxes || {}),
                ]) {
                    this.calculationForm.addControl(
                        control.id,
                        new UntypedFormControl(
                            { value: '', disabled: true },
                            Validators.required,
                        ),
                    );
                }

                if (this.position) {
                    this.kipoFService.getKipoFees().subscribe(
                        (data: any) => {
                            this.userFees = data;
                            this.getFeeByOriginAccount();

                            this.globalService.setOriginAccountType(
                                PRODUCT_TYPES.PAYPAL,
                            );
                        },
                        (err: any) => {
                            this.globalService.errorMessage(
                                err.error.message || err.message,
                            );
                        },
                    );
                }
            },
            (err: any) => {
                this.globalService.errorMessage(
                    err.error.message || err.message,
                );
            },
        );

        this.calculationForm.get('amount')?.valueChanges.subscribe(
            (controlValue: any) => {
                this.getTotalAmount(controlValue);
            },
            (err: any) => {
                this.globalService.errorMessage(
                    err.error.message || err.message,
                );
            },
        );
    }

    getTotalAmount(controlValue: any) {
        controlValue = parseFloat(controlValue || 0);
        if (controlValue === 0 || isNaN(controlValue)) {
            this.calculationForm
                .get(
                    this.template?.calculator?.fees[
                        this.template?.calculator?.fees.length - 1
                    ]?.id,
                )
                ?.setValue(null);
            this.calculationForm
                .get(this.template?.calculator?.taxes[0]?.id)
                ?.setValue(null);
            this.calculationForm.get('finalAmount')?.setValue(null);
            return;
        }

        let finalFee = 0;
        let finalTax = 0;

        this.template?.calculator?.fees.sort((a: any, b: any) => {
            if (a['type'] === 'accumulate') return 1;
            if (b['type'] === 'accumulate') return -1;
            return 0;
        });

        finalFee = this.calculateFees(controlValue);
        finalTax = this.calculateTax(finalFee);

        this.calculationForm.get('finalAmount')?.setValue(
            '$' +
                this.getRoundNumber(
                    controlValue + finalFee + finalTax,
                ).toLocaleString(this.localeId, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                }),
        );
        const accumulateFeeObj = this.template?.calculator?.fees.find(
            (fee: any) => fee.id === 'accumulateFee',
        );
        if (accumulateFeeObj) {
            this.accumulateFeeValue = 0;

            accumulateFeeObj.fields.forEach((field: string) => {
                const fieldValue = this.getNumberOnly(
                    this.calculationForm.get(field)?.value,
                );

                this.accumulateFeeValue += fieldValue;
            });

            let accumulateFeeRounded = this.getRoundNumber(
                this.accumulateFeeValue,
            ).toLocaleString(this.localeId, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            });
            this.calculationForm.controls['accumulateFee'].setValue(
                '$' + accumulateFeeRounded,
            );
            this.accumulateFeeEvent.emit(accumulateFeeRounded);
        }
        this.valueChange.emit(this.calculationForm.getRawValue());
    }

    private calculateFees(controlValue: number) {
        let finalFee = 0;
        for (const control of [...(this.template?.calculator?.fees || {})]) {
            let currentFeeAmount = 0;

            if (control.type === 'fixed') {
                const fixAmount = this.getRoundNumber(control.amount);
                currentFeeAmount += fixAmount;
            }

            if (control.type === 'percent') {
                const variableAmount = this.getRoundNumber(
                    (control.amount * controlValue) / 100,
                );
                currentFeeAmount += variableAmount;
            }

            if (control.type === 'accumulate') {
                let amount = 0;
                control.fields.forEach((field: string) => {
                    const value = this.getNumberOnly(
                        this.calculationForm.get(field)?.value,
                    );
                    amount += value;
                });
                this.calculationForm.get(control.id)?.setValue('$' + amount);
                continue;
            }

            this.calculationForm
                .get(control.id)
                ?.setValue('$' + currentFeeAmount);

            finalFee += currentFeeAmount;
        }
        return finalFee;
    }

    private calculateTax(finalFee: number) {
        let finalTax = 0;
        for (const control of [...(this.template?.calculator?.taxes || {})]) {
            let currentTaxAmount = 0;
            if (control.type === 'fixed') {
                const fixAmount = this.getRoundNumber(control.amount);
                currentTaxAmount += fixAmount;
            }

            if (control.type === 'variable') {
                const variableAmount = this.getRoundNumber(
                    (finalFee * control.amount) / 100,
                );
                currentTaxAmount += variableAmount;
            }

            this.calculationForm.get(control.id)?.setValue(
                '$' +
                    currentTaxAmount.toLocaleString(this.localeId, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    }),
            );
            finalTax += currentTaxAmount;
        }
        return finalTax;
    }

    getFeeByOriginAccount() {
        this.globalService.getOriginAccountType().subscribe(
            (productType: string) => {
                this.productType = productType;

                const data = this.userFees.filter(
                    (x: any) => x.productType === this.productType,
                );

                this.template?.calculator?.fees.map((fee: any) => {
                    const incomingFee = data.find(
                        (item: any) => item.name === fee.id,
                    );
                    if (incomingFee) {
                        fee.amount = incomingFee.value;
                    }
                });
                this.template?.calculator?.taxes.map((tax: any) => {
                    const incomingFee = data.find(
                        (item: any) => item.name === tax.id,
                    );
                    if (incomingFee) {
                        tax.amount = incomingFee.value;
                    }
                });
                const withdrawAmount =
                    this.calculationForm.get('amount')?.value;
                this.getTotalAmount(withdrawAmount);
            },
            (err: any) => {
                this.globalService.errorMessage(
                    err.error.message || err.message,
                );
            },
        );
    }
    resetForm() {
        this.ngOnInit();
    }

    private getRoundNumber(amount: any) {
        if (typeof amount === 'string') {
            amount = parseFloat(amount);
        }
        const multiplier = Math.pow(10, 2);

        return Math.round(amount * multiplier) / multiplier;
    }

    private getNumberOnly(value: string) {
        if (typeof value === 'string') {
            return this.getRoundNumber(value.substr(1));
        } else {
            return 0;
        }
    }

    allowNumericDigitsOnlyOnKeyUp(e: any) {
        if (/\D/g.test(e.target.value)) {
            e.target.value = e.target.value.replace(/\D/g, '');
            return false;
        }
        return true;
    }
}
