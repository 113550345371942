import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompleteKycComponent } from '@components/alerts/complete-kyc/complete-kyc.component';
import { AccountPendingComponent } from '@components/alerts/destination-account-pending/account-pending.component';
import { RejectedKycComponent } from '@components/alerts/rejected-kyc/rejected-kyc.component';
import { ReviewKycComponent } from '@components/alerts/review-kyc/review-kyc.component';
import { InactiveUserComponent } from '@components/alerts/inactive-user/inactive-user.component';
import { CalculatorFormComponent } from '@components/calculator/calculator-form/calculator-form.component';
import { PrepaidCardComponent } from '@components/prepaid-card/prepaid-card.component';
import { AuthCallbackComponent } from '@components/callback/callback.component';
import { DestinationAccountComponent } from '@components/destinationAccount/destination-account.component';
import { LogoutComponent } from '@components/logout/logout.component';
import { NavNotificatorComponent } from '@components/nav-notificator/nav-notificator.component';
import { InternalNavbarComponent } from '@components/navbar/internal-navbar.component';
import { BalanceComponent } from '@components/paypal/balance/balance.component';
import { PaypalCallbackComponent } from '@components/paypal/callback/callback.component';
import { DndDirective } from '@components/profile/dnd.directive';
import { ProfileComponent } from '@components/profile/profile.component';
import { ProgressComponent } from '@components/profile/progress/progress.component';
import { UploadComponent } from '@components/profile/upload/upload.component';
import { ConfirmComponent } from '@components/transaction/confirm/confirm.component';
import { DialogComponent } from '@components/transaction/dialog/dialog.component';
import { DisclaimerComponent } from '@components/transaction/disclaimer/disclaimer.component';
import { HistoryComponent } from '@components/transaction/history/history.component';
import { VoucherComponent } from '@components/transaction/voucher/voucher.component';
import { WithdrawComponent } from '@components/transaction/withdraw/withdraw.component';
import { AllowOnlyNumbersDirective } from '@directives/allow-only-numbers.directive';
import { ClickOutsideDirective } from '@directives/click-outside.directive';
import { NgxLayoutModule, NgxMaterialModule } from '@impesa/ngx-core';
import { NgxKycModule, ResolveKycService } from '@impesa/ngx-kyc';
import { TranslateModule } from '@ngx-translate/core';
import { UserInfoResolver } from '../../resolver/user-info.resolver';
import { PrivateRoutingModule } from './private-routing.module';
import { PrivateComponent } from './private.component';
import { FooterComponent } from '@components/footer/footer.component';
import {
    NgxMaskDirective,
    NgxMaskPipe,
    IConfig,
    provideEnvironmentNgxMask,
} from 'ngx-mask';
import { SafeUrlPipe } from '../../pipes/safe-url.pipe';
import { PrepaidCardFormComponent } from '@components/prepaid-card/prepaid-card-form/prepaid-card-form.component';
import { PrepaidCardAlertComponent } from '@components/prepaid-card/prepaid-card-alert/prepaid-card-alert.component';
import { AccountsBalanceComponent } from '@components/accounts-balance/accounts-balance.component';
import { PrepaidUserGuideComponent } from '../../components/prepaid-card/prepaid-user-guide/prepaid-user-guide.component';
import { InactiveDisclaimerComponent } from '../../components/destinationAccount/inactive-disclaimer/inactive-disclaimer.component';
import { ViewAttachmentComponent } from '../../components/attachment/view-attachment/view-attachment.component';
import { PreviewDocumentComponent } from '../../components/attachment/preview-document/preview-document.component';
import { ImageViewerDirective } from '../../directives/image-viewer.directive';

const maskConfig: Partial<IConfig> = {
    validation: false,
};
@NgModule({
    declarations: [
        AllowOnlyNumbersDirective,
        PrivateComponent,
        InternalNavbarComponent,
        NavNotificatorComponent,
        WithdrawComponent,
        DisclaimerComponent,
        BalanceComponent,
        ConfirmComponent,
        HistoryComponent,
        VoucherComponent,
        LogoutComponent,
        PaypalCallbackComponent,
        ProfileComponent,
        DestinationAccountComponent,
        CalculatorFormComponent,
        AuthCallbackComponent,
        UploadComponent,
        ProgressComponent,
        DndDirective,
        DialogComponent,
        CompleteKycComponent,
        ReviewKycComponent,
        RejectedKycComponent,
        AccountPendingComponent,
        ClickOutsideDirective,
        FooterComponent,
        SafeUrlPipe,
        PrepaidCardComponent,
        PrepaidCardAlertComponent,
        PrepaidCardFormComponent,
        AccountsBalanceComponent,
        PrepaidUserGuideComponent,
        InactiveDisclaimerComponent,
        ViewAttachmentComponent,
        PreviewDocumentComponent,
        ImageViewerDirective,
        InactiveUserComponent,
    ],
    imports: [
        CommonModule,
        NgxMaterialModule,
        PrivateRoutingModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        NgxLayoutModule,
        NgxKycModule,
        NgxMaskDirective,
        NgxMaskPipe,
    ],
    exports: [
        CalculatorFormComponent,
        PrepaidCardComponent,
        PrepaidCardAlertComponent,
        PrepaidCardFormComponent,
        FormsModule,
    ],
    providers: [
        ResolveKycService,
        UserInfoResolver,
        provideEnvironmentNgxMask(maskConfig),
    ],
})
export class PrivateModule {}
