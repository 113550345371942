import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent, BootService } from '@impesa/ngx-core';
import { KipoService } from '../../../core/kipo.service';
import { PreviewDocumentComponent } from '../preview-document/preview-document.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-view-attachment',
    templateUrl: './view-attachment.component.html',
    styleUrls: ['./view-attachment.component.scss'],
})
export class ViewAttachmentComponent extends BaseComponent implements OnInit {
    @Input() attachment!: string;
    @Input() fileName!: string;

    public URL: any;
    public showPdf: boolean = false;

    constructor(
        private kipoService: KipoService,
        boot: BootService,
        private sanitizer: DomSanitizer,
    ) {
        super(boot);
    }

    ngOnInit(): void {
        this.loadAttachment();
    }

    public loadAttachment() {
        if (this.attachment) {
            this.kipoService.getAttachment(this.attachment).subscribe(
                (file: any) => {
                    this.render(file, this.getBlobData(this.fileName));
                },
                (err: any) => {
                    console.log(err);
                },
            );
        }
    }

    private getBlobData(fileName: string): string {
        const extension = fileName.split('.')[1];
        let contenType;

        switch (extension.toLowerCase()) {
            case 'jpg':
                contenType = 'image/jpg';
                break;
            case 'png':
                contenType = 'image/png';
                break;
            case 'pdf':
                contenType = 'application/pdf';
                this.showPdf = true;
                break;
            default:
                break;
        }

        return contenType ?? '';
    }

    private render(data: any, contenType: string) {
        const blob = new Blob([data], { type: contenType });
        this.URL = URL.createObjectURL(blob);
    }

    public showAttachment() {
        this.dialogService.dialog.open(PreviewDocumentComponent, {
            width: '60%',
            height: '100%',
            data: {
                URL: this.URL,
                showPdf: this.showPdf,
            },
        });
    }
}
