import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PrivateComponent } from './layout/private/private.component';
import { AuthCallbackComponent } from '@components/callback/callback.component';
import { HomeComponent } from '@views/home/home.component';

const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: HomeComponent },
    { path: 'signup', component: HomeComponent },
    {
        path: 'authorization/callback',
        component: AuthCallbackComponent,
    },
    {
        path: '',
        component: PrivateComponent,
        children: [
            {
                path: '',
                redirectTo: 'app',
                pathMatch: 'full',
            },
            {
                path: 'app',
                loadChildren: () =>
                    import('./layout/private/private.module').then(
                        (m) => m.PrivateModule,
                    ),
            },
        ],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',
            initialNavigation: 'enabledNonBlocking',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
