<div
    class="panel panel-short pt-2 w-100"
    [ngClass]="{
        'panel-card': showBalance,
        'account-color': showBalance,
        border: showBalance,
        height: showBalance,
        loader: !productDateEnable && !showStatus && !showRequest,
        'p-0': showStatus
    }"
    [ngStyle]="{
        height:
            !productDateEnable && !showStatus && !showRequest ? '100px' : 'auto'
    }"
>
    <div
        *ngIf="showRequest"
        class="prepaid-affiliate d-flex justify-content-center col-md-12 col-xs-6 pl-0 pr-0"
    >
        <img
            class="d-inline-block align-top img-product"
            src="/assets/CRI/prepaid/kipo-prepaid-card.png"
            alt="prepaid-card-img"
        />
        <div class="description">
            <h1>{{ 'prepaidCard.description' | translate }}</h1>

            <p>{{ 'prepaidCard.requestCard' | translate }}</p>
        </div>

        <button class="btn-background" (click)="addPrepaidCard()">
            {{ 'prepaidCard.buttonAffiliate' | translate }}
        </button>
    </div>

    <div
        *ngIf="showStatus"
        class="prepaid-affiliate d-flex justify-content-center"
    >
        <img
            class="d-inline-block align-top img-product"
            src="/assets/CRI/prepaid/kipo-prepaid-card.png"
            alt="prepaid-card-img"
        />
        <div class="description mt-4">
            <h1>
                {{ balanceMessage }}
            </h1>
        </div>
    </div>
    <div class="flex-wrap card-tablet card-margin">
        <h2 class="mt-0 mb-2 text-left" *ngIf="showBalance">
            {{ 'prepaidCard.prepaidCardSubTitle' | translate }}
        </h2>
        <div
            *ngIf="showBalance"
            class="prepaid-affiliate d-flex justify-content-center"
        >
            <img
                class="d-inline-block align-top img-product"
                src="{{ _metadataCard?.image }}"
                alt="prepaid-card-img"
            />
            <div class="description d-flex flex-column prepaid-card">
                <h1 class="mb-0">
                    {{ 'paypal.labelBalance' | translate }}
                    <p class="mb-0">{{ _balance | currency : 'USD' }}</p>
                </h1>
            </div>
        </div>
    </div>
</div>
