import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { KipoService } from '@core/kipo.service';
import { GlobalVarsService } from '../../../shared/services/global-vars/global-vars.service';

@Component({
    selector: 'app-paypal-callback',
    templateUrl: './callback.component.html',
    styleUrls: ['./callback.component.scss'],
})
@Injectable()
export class PaypalCallbackComponent implements OnInit {
    constructor(
        private activatedRoute: ActivatedRoute,
        private kipoService: KipoService,
        private router: Router,
        private globalService: GlobalVarsService,
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(
            (params: Params) => {
                if (!params?.code) {
                    this.router.navigate(['app/withdraw']).catch(() => {
                        console.log('Error navigating to withdraw');
                    });
                    return;
                }
                if (params?.code) {
                    this.kipoService.postPaypalAccount(params?.code).subscribe(
                        (result: any) => {
                            this.globalService.setPaypalAccount(result);
                            this.router.navigate(['app/withdraw']).catch(() => {
                                console.log('Error navigating to withdraw');
                            });
                        },
                        (err: any) => {
                            this.router
                                .navigate(['app/withdraw'], {
                                    queryParams: {
                                        message: err?.error?.message,
                                        code: err?.error?.message || null,
                                    },
                                })
                                .catch(() => {
                                    console.log('Error navigating to withdraw');
                                });
                        },
                    );
                }
            },
            (err: any) => {
                this.globalService.errorMessage(
                    err.error.message || err.message,
                );
            },
        );
    }
}
