import { Component, OnInit } from '@angular/core';
import { KipoService } from '../../core/kipo.service';

@Component({
    selector: 'app-calculator',
    templateUrl: './calculator.component.html',
    styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent implements OnInit {
    constructor(protected readonly kipoFService: KipoService) {}

    ngOnInit(): void {}
}
