import { Component, Inject, OnInit } from '@angular/core';
import { KipoService } from '@core/kipo.service';
import { countriesEnum } from '@enums/country.enum';
import { Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import * as countriesMetas from '../../../assets/kipo/countriesMetas.json';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
    public country!: string;
    public countries = countriesEnum;

    private countriesMetasArray = countriesMetas;
    private metatags!: any[];
    private generalMetas!: any[];

    constructor(
        protected readonly kipoService: KipoService,
        private metaService: Meta,
        @Inject(DOCUMENT) private doc: any,
    ) {
        this.country = this.kipoService.getCountry();
        this.metatags =
            this.countriesMetasArray[
                this.country as keyof typeof this.countriesMetasArray
            ];
        this.generalMetas = this.countriesMetasArray.GENERAL;
        this.metaService.addTags(this.generalMetas);
        this.metaService.addTags(this.metatags);
        this.getCanonicalLink();
    }

    ngOnInit() {
        setTimeout(() => {
            location.reload();
        }, 1000);
    }

    getCanonicalLink() {
        let link: HTMLLinkElement = this.doc.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.doc.head.appendChild(link);
        link.setAttribute('href', this.doc.URL);
    }
}
