<div
    class="panel panel-short pb-0 mb-3 d-flex flex-column justify-content-between pt-3"
    [ngClass]="{
        'card-panel': !showRequest && _card && hasBalance,
        'panel-height': !showRequest && !_card,
        'account-color': !showRequest && _card,
        'mt-2': _card
    }"
>
    <div>
        <h1 class="mb-3 pt-1 text-center" *ngIf="!_card">
            {{ 'withdraw.panelTitleOne' | translate }}
        </h1>

        <div
            class="text-center"
            *ngIf="!_paypalAccount || !_paypalBalance"
            (click)="doPaypalLogin()"
        >
            <p
                class="sub-title mt-0 text-center"
                [ngClass]="{
                    'mb-5': !_card,
                    'mt-3': !_card
                }"
            >
                {{ 'withdraw.addPaypalAccount' | translate }}
            </p>
            <a class="textReference" [routerLink]="['/app/withdraw']">
                <span
                    class="lipp-button"
                    id="cwppButton"
                    style="pointer-events: auto"
                    ><a
                        id="LIwPP73291355"
                        class="LIwPP_V2 PPBlue_V2 balance-logInWithPaypal"
                        [ngClass]="{
                            LIwPP_V3: !showRequest && _card
                        }"
                        text="Connect with PayPal"
                        href="javascript:;"
                        pa-marked="1"
                        ><svg
                            class="PPTM"
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            width="25px"
                            height="25px"
                            viewBox="0 0 18 18"
                            focusable="false"
                        >
                            <path
                                id="Fill-8"
                                class="PPTM-btm"
                                fill="#009cde"
                                d="M13.6023044,4.67342223 C13.8196336,3.28812419 13.6008532,2.34542498 12.8514484,1.49160831 C12.0263956,0.551629995 10.5359309,0.148937623 8.62894883,0.148937623 L3.09322219,0.148937623 C2.70355347,0.148937623 2.37175455,0.43245482 2.3109822,0.817370681 L0.00598653054,15.4327457 C-0.0395473836,15.7211605 0.183405526,15.9818221 0.475294243,15.9818221 L3.89269613,15.9818221 L3.65686311,17.4774071 C3.61713431,17.7297247 3.81196867,17.957917 4.06739398,17.957917 L6.94782221,17.957917 C7.28887304,17.957917 7.57912907,17.7097715 7.63228221,17.3731061 L7.66058217,17.226722 L8.20317953,13.7860604 L8.23819166,13.5959605 C8.29134479,13.2591138 8.58160082,13.0111496 8.92247024,13.0111496 L9.35331903,13.0111496 C12.1439493,13.0111496 14.329033,11.8774436 14.9674148,8.59894727 C15.2342689,7.22906764 15.0963973,6.08556642 14.3908938,5.28163282 C14.1773742,5.03856625 13.9119713,4.83740146 13.6023044,4.67342223"
                            ></path>
                            <path
                                id="Fill-9"
                                class="PPTM-top"
                                fill="#012169"
                                d="M13.6023044,4.67384766 C13.8196336,3.28841937 13.6008532,2.34563151 12.8514484,1.49173456 C12.0263956,0.55166786 10.5359309,0.148937623 8.62894883,0.148937623 L3.09322219,0.148937623 C2.70355347,0.148937623 2.37175455,0.432481479 2.3109822,0.817433533 L0.00598653054,15.4341828 C-0.0395473836,15.7226247 0.183405526,15.9833109 0.475294243,15.9833109 L3.89269613,15.9833109 L4.75112833,10.5399219 L4.72446105,10.7106288 C4.78523341,10.3256767 5.11431118,10.0419515 5.50397989,10.0419515 L7.12832518,10.0419515 C10.3182389,10.0419515 12.815892,8.7459583 13.5457045,4.99802736 C13.5672923,4.88718584 13.5857961,4.7796097 13.6023044,4.67384766"
                                fill-opacity="0.699999988079071"
                            ></path>
                            <path
                                id="Fill-10"
                                class="PPTM-top"
                                fill="#003087"
                                d="M5.67323544,4.69235148 C5.70988026,4.46069089 5.85845507,4.27111742 6.05855031,4.17515152 C6.14961814,4.13161312 6.25120775,4.10730418 6.35769543,4.10730418 L10.6968416,4.10730418 C11.2109576,4.10730418 11.6902429,4.14104644 12.128348,4.21161494 C12.2537024,4.23175145 12.3754285,4.25497193 12.4938892,4.28127638 C12.6121686,4.30739943 12.7271825,4.33678785 12.8383869,4.36944165 C12.8940797,4.38558714 12.9486841,4.40263969 13.0025629,4.42059928 C13.2177152,4.49189341 13.4179919,4.57624907 13.6023044,4.67384766 C13.8196336,3.28841937 13.6008532,2.34563151 12.8514484,1.49173456 C12.0263956,0.55166786 10.5359309,0.148937623 8.62894883,0.148937623 L3.09322219,0.148937623 C2.70355347,0.148937623 2.37175455,0.432481479 2.3109822,0.817433533 L0.00598653054,15.4341828 C-0.0395473836,15.7226247 0.183405526,15.9833109 0.475294243,15.9833109 L3.89269613,15.9833109 L5.67323544,4.69235148"
                            ></path>
                        </svg>
                        <b class="" aria-label="Connect with PayPal">{{
                            'paypal.btnPayPalLogin' | translate
                        }}</b>
                    </a>
                </span>
            </a>
        </div>
        <div *ngIf="_paypalBalance">
            <h2 class="mt-0 mb-1 text-left" *ngIf="_card">
                {{ 'paypal.paypalAccountTitle' | translate }}
            </h2>
            <div>
                <div
                    class="email-font overflow title-liner"
                    [ngClass]="{ 'pt-1': _card }"
                >
                    <span class="align-baseline title-liner">
                        {{ 'paypal.labelAccount' | translate }}
                        <span class="resaltText">{{
                            _paypalAccount.email
                        }}</span>
                    </span>
                </div>
                <div [ngClass]="{ 'pt-2': _card, 'pt-3': !_card }">
                    <span class="align-baseline title-liner"
                        >{{ 'paypal.labelAccuntType' | translate }}
                        <span class="resaltText">{{
                            _paypalAccount.accountType | lowercase
                        }}</span>
                    </span>
                </div>
                <div [ngClass]="{ 'pt-2': _card, 'pt-3': !_card }">
                    <span class="align-baseline title-liner">{{
                        'paypal.labelBalance' | translate
                    }}</span
                    ><br />
                    <h6
                        *ngIf="_paypalBalance"
                        class="overflow"
                        [ngClass]="{ 'prepaid-card': _card }"
                    >
                        {{
                            (_paypalBalance.available
                                ? _paypalBalance.available.value
                                : 0
                            ) | currency : 'USD'
                        }}
                    </h6>
                </div>
            </div>
        </div>
        <div
            *ngIf="
                _withdrawForm.controls.paypalAccountId &&
                _withdrawForm.controls.paypalAccountId.invalid &&
                _withdrawForm.controls.paypalAccountId.touched
            "
            class="cross-validation-error-message alert alert-danger"
        >
            <div
                *ngIf="_withdrawForm.controls.paypalAccountId.errors?.required"
            >
                {{ 'withdraw.alertMessagePaypal' | translate }}
            </div>
        </div>
        <div
            [ngClass]="{
                'sub-title-width': !_card && !_paypalAccount && !showRequest,
                'text-alert': showRequest || _card
            }"
        >
            <p
                class="text-left sub-title"
                *ngIf="_withdrawForm.get('paypalAccountId')?.errors"
            >
                {{ 'withdraw.panelLabelAlertPayPal' | translate }}
            </p>
        </div>
    </div>
</div>
