<div class="div height">
    <div class="pb-3">
        <mat-icon class="logo-icon">report</mat-icon>
        <p class="font-weight-bold text h6">
            {{ 'withdraw.alertCompleteInformation' | translate }}
        </p>
    </div>

    <button
        *ngIf="!viewLabel"
        class="btn btn-secondary btn-block w-auto m-auto alert-completeKycBtn"
        type="button"
        [routerLink]="['/app/profile']"
        [queryParams]="{ completeKyc: true }"
    >
        {{ 'withdraw.completeInformation' | translate }}
    </button>
    <button
        *ngIf="viewLabel"
        class="btn btn-secondary btn-block mt-3"
        type="button"
        class="alert-viewKycBtn"
        [routerLink]="['/app/profile']"
        [queryParams]="{ completeKyc: true }"
    >
        {{ 'withdraw.viewInformation' | translate }}
    </button>
</div>
