<div class="voucher-content margin">
    <div class="main-content">
        <div class="outer">
            <div class="inner">
                <div class="content">
                    <dl class="row items">
                        <dt class="col-sm-6">
                            <br />
                        </dt>
                        <dd class="col-sm-6">
                            <br />
                        </dd>
                    </dl>
                    <div class="text-center">
                        <img
                            alt="Kipo voucher logo"
                            src="./assets/kipo/kipo-logo-fondo-azul.svg"
                            srcset="./assets/kipo/kipo-logo-fondo-azul.svg"
                        />

                        <div
                            class="flex d-flex justify-content-center col-sm-6 col-md-12 mx-auto"
                        >
                            <div class="col-md-10 pl-0 pr-0 mx-auto">
                                <p class="title-voucher pt-3">
                                    {{
                                        'withdraw.voucher.panelTitle'
                                            | translate
                                    }}
                                </p>

                                <hr class="mt-3" />
                                <p class="subtitle-voucher pt-3">
                                    {{
                                        'withdraw.voucher.panelSubTitle'
                                            | translate
                                    }}
                                </p>
                                <p class="transaccion-voucher pt-1">
                                    {{ transaction.withdrawResult.ref2 }}
                                </p>

                                <hr class="mt-3" />

                                <div class="date-voucher pt-3">
                                    <dl class="row">
                                        <dt class="col-sm-5 w-auto item">
                                            {{
                                                'withdraw.voucher.panelLabelDate'
                                                    | translate
                                            }}
                                        </dt>
                                        <dd
                                            class="col-sm-7 w-auto item i padding"
                                        >
                                            {{
                                                transaction.date
                                                    | date : 'MM/dd/yy h:mm a'
                                            }}
                                        </dd>
                                    </dl>
                                    <dl class="row">
                                        <dt class="col-sm-6 w-auto item">
                                            {{
                                                'withdraw.voucher.panelLabelDatepost'
                                                    | translate
                                            }}
                                        </dt>
                                        <dd
                                            *ngIf="transaction?.realTime"
                                            class="col-6 item i realTime-text"
                                        >
                                            {{
                                                'withdraw.voucher.panelLabelThreeRealTime'
                                                    | translate
                                            }}
                                        </dd>

                                        <dd
                                            *ngIf="!transaction?.realTime"
                                            class="col-sm-6 w-auto item i"
                                        >
                                            {{
                                                'withdraw.voucher.panelLabelDatepostValue'
                                                    | translate
                                            }}
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <i class="bottom right"></i>
                <i class="bottom left"></i>
            </div>
        </div>
    </div>
    <div class="content-bottom">
        <div class="outer">
            <div class="inner">
                <i class="top left"></i>
                <i class="top right"></i>
                <div class="content">
                    <div class="flex d-flex justify-content-center">
                        <div class="col-md-10">
                            <div class="pt-3">
                                <dl class="row">
                                    <dt class="col-6">
                                        {{
                                            'withdraw.voucher.panelLabelAmount'
                                                | translate
                                        }}
                                    </dt>
                                    <dd class="col-6 w-auto">
                                        {{
                                            transaction?.amount?.amount.replace(
                                                ',',
                                                '.'
                                            ) | currency : 'USD'
                                        }}
                                    </dd>
                                </dl>

                                <dl class="row">
                                    <dt class="col-sm-6 w-auto">
                                        {{
                                            'withdraw.voucher.panelLabelFee'
                                                | translate
                                        }}
                                    </dt>
                                    <dd class="col-6">
                                        {{
                                            accumulateFeeValue
                                                | currency : 'USD'
                                        }}
                                    </dd>
                                </dl>
                                <dl
                                    class="row"
                                    *ngIf="
                                        transaction.amount?.ITBMS ||
                                        transaction.amount?.IVA
                                    "
                                >
                                    <dt class="col-sm-6 w-auto">
                                        ({{
                                            'transactions.labelCountryFee'
                                                | translate
                                        }}
                                        {{ IVA }}%)
                                    </dt>
                                    <dd class="col-sm-6 w-auto">
                                        {{
                                            transaction.amount?.ITBMS ||
                                                transaction.amount?.IVA
                                        }}
                                    </dd>
                                </dl>

                                <dl class="row">
                                    <dt class="col-sm-6 w-auto">
                                        {{
                                            'withdraw.voucher.panelLabelAmountDeb'
                                                | translate
                                        }}
                                    </dt>
                                    <dd class="col-sm-6 w-auto">
                                        {{ transaction.amount?.finalAmount }}
                                    </dd>
                                </dl>

                                <dl class="row">
                                    <dt class="col-sm-5 w-auto">
                                        {{
                                            'withdraw.voucher.panelLabelTargetAccount'
                                                | translate
                                        }}
                                    </dt>
                                    <dd class="col-sm-7 w-auto">
                                        {{ transaction.sinpeAccount }}
                                    </dd>
                                </dl>

                                <dl class="row w-auto">
                                    <dt class="col-sm-6 w-auto">
                                        {{
                                            'withdraw.voucher.panelLabelIssuerAccount'
                                                | translate
                                        }}
                                    </dt>
                                    <dd class="col-sm-6 w-auto">
                                        {{ issuerName }}
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pt-3 text-center">
        <button
            class="btn btn-secondary m-3 voucher-newTransactionBtn"
            (click)="resetStepper()"
        >
            {{ 'withdraw.voucher.panelLabelNewTransacction' | translate }}
        </button>
        <button
            class="btn btn-secondary m-3 voucher-printTransaction"
            (click)="callParentPrint()"
        >
            {{ 'withdraw.voucher.panelLabelPrint' | translate }}
        </button>
    </div>
</div>
