<mat-spinner *ngIf="render" class="mx-auto mt-2 mb-2"></mat-spinner>

<div *ngIf="!render" class="col-sm-12 col-md-12 px-0">
    <div class="header wave">
        <div class="col-md-12 text-center">
            <h3 class="header-title mt-3" *ngIf="!data?.account">
                {{ 'sinpe.saveAccount' | translate }}
            </h3>
            <h3 class="header-title mt-3" *ngIf="data?.account">
                {{ 'sinpe.editAccount' | translate }}
            </h3>
        </div>
    </div>

    <div class="content-modal">
        <form [formGroup]="accountForm">
            <div
                *ngFor="
                    let field of template?.destinationAccount;
                    let i = index
                "
            >
                <div
                    class="input-group mb-3"
                    *ngIf="field.type !== 'uploadFile'"
                >
                    <div class="input-group-prepend">
                        <label
                            for="ownerId"
                            class="input-group-text text-left"
                            id="inputGroup-sizing-default"
                            >{{ field.label }}</label
                        >
                    </div>

                    <select
                        *ngIf="
                            field.type === 'select' &&
                            field.source === 'issuers'
                        "
                        formControlName="{{ field.id }}"
                        name="{{ field.id }}"
                        id="{{ field.id }}"
                        class="form-control destinationAccountForm-selectIssuers"
                        (change)="validateIssuer()"
                    >
                        <option
                            *ngFor="let element of issuers"
                            [ngValue]="element"
                        >
                            {{ element.description }}
                        </option>
                    </select>

                    <input
                        *ngIf="field.readonly && field.type === 'input'"
                        id="ownerId"
                        class="form-control destinationAccountForm-ownerId"
                        formControlName="{{ field.id }}"
                        name="ownerId"
                        readonly
                    />

                    <input
                        *ngIf="
                            !field.readonly &&
                            field.type === 'input' &&
                            !field.allowOnlyNumber
                        "
                        id="{{ field.id }}"
                        class="form-control destinationAccountForm-accountAlias"
                        formControlName="{{ field.id }}"
                        name="{{ field.id }}"
                        [dropSpecialCharacters]="true"
                        [mask]="field.patternMask ? field.patternMask : null"
                        placeholder="{{ !isMobile ? field.placeholder : '' }}"
                        [ngClass]="{
                            'text-uppercase': field.id === 'accountNumber'
                        }"
                        maxlength=" {{ field.maxlength }}"
                    />
                    <input
                        *ngIf="
                            !field.readonly &&
                            field.type === 'input' &&
                            field.allowOnlyNumber
                        "
                        id="{{ field.id }}"
                        class="form-control destinationAccountForm-accountNumber"
                        allowOnlyNumber
                        formControlName="{{ field.id }}"
                        name="{{ field.id }}"
                        placeholder="{{ !isMobile ? field.placeholder : '' }}"
                        maxlength=" {{ field.maxlength }}"
                    />
                    <select
                        *ngIf="
                            field.type === 'select' &&
                            field.source === 'accountTypes'
                        "
                        formControlName="{{ field.id }}"
                        name="{{ field.id }}"
                        id="{{ field.id }}"
                        class="form-control destinationAccountForm-selectAccountTypes"
                    >
                        <option
                            *ngFor="let element of accountTypes"
                            value="{{ element.id }}"
                        >
                            {{ element.name }}
                        </option>
                    </select>
                </div>
                <small *ngIf="field.id === 'accountNumber' && isMobile"
                    >{{ field.placeholder }}
                </small>
                <div *ngIf="!isValidAccount()">
                    <small *ngIf="field.id === 'accountNumber'"
                        >{{ 'sinpe.panelLabelIbanAlertRequiered' | translate }}
                    </small>
                    <div>
                        <small *ngIf="field.id === 'accountNumber'">{{
                            field.description
                        }}</small>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="showAddAttachment">
                <div class="col-12">
                    <small>{{ 'sinpe.documentDisclaimer' | translate }}</small>
                </div>
                <div class="col-12">
                    <app-upload
                        [inParentGroup]="accountForm"
                        [inDefaultLoad]="false"
                        [attachmentId]="data?.account?.attachmentId"
                        [attachmentName]="data?.account?.fileName"
                        [account]="data?.account"
                        accept=".pdf,.jpg,.png,.jpeg"
                        (notifyParent)="attachmentChanged($event)"
                    >
                    </app-upload>
                </div>
            </div>

            <div class="pt-3 text-center">
                <div class="row">
                    <div class="col-6">
                        <button
                            type="button"
                            class="btn btn-primary col-6 cancelButton destinationAccountForm-cancelBtn"
                            (click)="cancel()"
                        >
                            {{ 'sinpe.panelButtonCancelar' | translate }}
                        </button>
                    </div>
                    <div class="col-6">
                        <button
                            [disabled]="accountForm.invalid"
                            class="btn btn-secondary col-6 saveButton destinationAccountForm-saveBtn"
                            (click)="saveData()"
                            [disabled]="isLoading"
                            [disabled]="!isValidAccount()"
                        >
                            {{ 'sinpe.panelButtonGuardar' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
