import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    ResolveFn,
    RouterStateSnapshot,
} from '@angular/router';
import { KipoService } from '@core/kipo.service';
import { forkJoin } from 'rxjs';

export const CountrySettingsResolverFn: ResolveFn<any> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const resolverKipoService = inject(KipoService);

    const obsArg = {
        countrySettings: resolverKipoService.getCountrySettingsByCode(
            resolverKipoService.getCountry(),
        ),
    };

    return forkJoin(obsArg);
};
