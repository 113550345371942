import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog',
    styleUrls: ['dialog.component.scss'],
    templateUrl: 'dialog.component.html',
})
export class DialogComponent {
    public dialogType: any;

    constructor(
        public dialogRef: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}
}
